.tab { cursor:pointer; }
.mt-10 { margin-top: -10rem;}
.home-slide-tabs {
    width:101%;
    height:46rem;
    position:fixed;
    top:48rem;
    left:50%;
    transform:translateX(-50%);
    z-index:100;
    padding:16rem;
    background:#fff;
    overflow-x:scroll;
    overflow-y:hidden;
    border-bottom:solid 1px #F2F2F2;

    // 스크롤바 숨기기
    @include hideScrollBar();

    .tabs {
        display:flex;
        gap:24rem;

        @include respond(pc) {
            justify-content: center;
        }

        @include respond(tablet) {
            justify-content: center;
        }

        .tab {
            display:inline-block;
            font-size:16rem;
            font-weight:500;
            color:#91979E;
            position:relative;
            white-space: nowrap;

            &.active {
                color:$yellow;
                font-weight:700;
                &::before {
                    content:'';
                    display:inline-block;
                    width:100%;
                    height:4rem;
                    background:$yellow;
                    position:absolute;
                    bottom:-12rem;
                    left:0;
                }

            }
            &:last-child {
                padding-right:16rem;

                &::before {
                    width:calc(100% - 16rem);
                }
            }

        }
    }
}
.slide-tabs {
    width:100%;
    height:46rem;
    position:fixed;
    top:48rem;
    left:50%;
    transform:translateX(-50%);
    z-index:100;
    padding:16rem;
    background:#fff;
    overflow-x:scroll;
    overflow-y:hidden;

    // 스크롤바 숨기기
    @include hideScrollBar();

    .tabs {
        display:flex;
        gap:24rem;
        
        @include respond(pc) {
            justify-content: center;
        }

        .tab {
            display:inline-block;
            font-size:16rem;
            font-weight:500;
            color:#91979E;
            position:relative;
            white-space: nowrap;

            &.active {
                color:$yellow;
                font-weight:700;
                &::before {
                    content:'';
                    display:inline-block;
                    width:100%;
                    height:4rem;
                    background:$yellow;
                    position:absolute;
                    bottom:-12rem;
                    left:0;
                }

            }
            &:last-child {
                padding-right:16rem;

                &::before {
                    width:100%;
                    left: -8rem;
                }
            }

        }
    }

    &.purple {
        // border-bottom:solid 1px $black40;
        .tab {
            &.active {
                color:$purple;
                &::before {
                    background:$purple;
                    width:100%;
                }
            }
        }
    }

    &.center {
        .tabs {
            justify-content: center;
            .tab {
                width:100%;
                max-width:82rem;
            }
        }
    }
}
.common-tab-wrap {
    .tabs {
        display:flex;
        width:100%;
        display: none;
        .tab {
            width:33.3333333333%;
            height:42rem;
            text-align: center;
            background:$black20;;
            border:solid 1px $black50;
            border-right:none;
            font-size:14rem;
            color:$black80;
            &:last-child {
                border-right:solid 1px $black50;
            }
            &.active {
                background:#fff;
                font-weight:700;
                border-bottom:none;
            }
        }        
    }
    .content {
        // padding:15rem 16rem;
        // border:solid 1px $black50;
        border-top:none;
        display:flex;
        flex-direction: column;
        gap:10rem;
        .text-wrap {
            display:flex;
            gap:10rem;
            .title {
                width:80rem;
                font-size:14rem !important;
                font-weight:700;
                flex-shrink:0;
                color:$black80;
            }
            .text {
                display:flex;
                gap:2rem;
                flex-direction: column;
                span {
                    font-size:14rem !important; 
                    color:$black80;
                    line-height:22rem;
                }
            }
        }
    }
}
.category-tabs-wrap {
    position: relative;
    overflow: hidden;
    // margin-left: 16rem;
    
    // @include respond(pc) {
    //     margin: 0 auto;
    // }
    .category-swiper {
        overflow: visible; /* overflow가 보이도록 설정 */
        padding-right: 16rem;

        .swiper-slide {
            display: flex;  // SwiperSlide의 display 설정
            align-items: center;
            justify-content: center;
            cursor: pointer;

            // SwiperSlide 내의 li 태그 스타일을 직접 적용
            li {
                height: 38rem;
                border-radius: 20rem;
                background-color: $black20;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;  // Padding과 border를 요소의 전체 너비와 높이에 포함시킴

                button {
                    font-size: 14rem;
                    color: $black60;
                    text-align: center;
                    width:100%;
                    height: 100%;
                    min-width:60rem;
                    padding:0 14rem;
                    white-space: nowrap;
                }

                &.active {
                    border: solid 1px $purple;
                    background: $purple10;
                    color: $purple !important;
                    font-weight: 700;
                    pointer-events: none;
                    
                    a {
                        color: $purple !important;
                        font-weight: 700;
                        font-size: 14rem;
                    }
                }
            }
        }

        &:not(.big) {
            .swiper-slide { width: auto !important }
        }

        .swiper-wrapper {
            display: flex;
            align-items: center;
        }
    }
    .tabs {
        // display:flex;
        display:block !important;
        border-bottom:solid 1px $black30;
        padding:20rem 16rem;
        gap: 8rem !important;
        border: none !important;
        padding: 0 13rem 0 0 !important;
        
        .tab {
            min-width:52rem;
            padding:5rem 16rem;
            text-align: center;
            border-radius:20rem;
            border:solid 1px $black50;
            font-size:14rem;
            color:$black60;
            &.active {
                border:solid 1px $purple;
                background:$purple10;
                color:$purple !important;
                font-weight:700;
                a {
                    color:$purple !important;
                    font-weight:700;
                    font-size:14rem;
                }
            }
        }        
    }
    &.big {
        .tabs {
            
            .tab {
                display:flex;
                align-items: center;
                justify-content: center;
                border-radius:8rem;
                width: calc(100% - 8rem);
                height: 80rem;
                padding: 0 10rem;
                background-color: #fff;
                border: 1px solid $black40;

                .badge.gray {
                    display: inline-block;
                    margin-top:2rem;
                    padding:4rem 8rem;
                    background:$black50;
                    color:#fff;
                    border-radius:4rem;
                    font-weight:400;
                }
                a {
                    font-size:14rem;
                    word-break: keep-all;
                }
            }
        }

        .category-swiper {
            .swiper-slide {
                display: flex;  // SwiperSlide의 display 설정
                align-items: center;
                justify-content: center;
                height: 80rem;  // SwiperSlide의 높이
                border-radius: 8rem;

                .badge.gray {
                    display: inline-block;
                    margin-top: 2rem;
                    padding: 4rem 8rem;
                    background: $black50;
                    color: #fff;
                    border-radius: 4rem;
                    font-weight: 400;
                }

                a {
                    font-size: 14rem;
                    word-break: break-all;
                }
            }

            .swiper-wrapper {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 20rem 0 10rem 0; 
            }

            .swiper-pagination-horizontal {
                position: relative;
            }

            /* Swiper 기본 스타일 (필요에 따라 조정) */
            .swiper {
                position: relative;
                overflow: hidden;
            }

            .swiper-pagination-bullet-active {
                background: $purple !important; /* 활성화된 페이지네이션 색상 */
            }
        }
    }
    &.recent-search {
        .tabs {
            display:flex !important;
        }
    }

    .active {
        width: 100%;
        height: 100%;
        border: solid 1px $purple !important;
        border-radius: 20rem;
        background: $purple10;
        color: $purple !important;
        font-weight: 700;
        pointer-events: none;

        a {
            color: $purple !important;
            font-weight: 700;
            font-size: 14rem;
        }
    }
}
.inquiry-tab-wrap {

    margin-bottom:16rem;

    .tabs {

        display: flex;

        .tab {
            display:flex;
            align-items: center;
            font-size:14rem;
            color:$black50;

            &::after {
                content:"";
                display:inline-block;
                width:1px;
                height:12rem;
                background:$black80;
                margin:0 10rem;
            }

            &:last-child::after {
                display: none;
            }

            &.active {

                color:$black80;

                &::before {
                    content:"";
                    display:inline-block;
                    width:20rem;
                    height:20rem;
                    background:url('../../../images/common/icons/icon_check_black.svg') no-repeat center;
                    background-size:contain;
                    margin-right:1rem;
                }
            }
        }

    }    
}
.brand-tabs {
  display: flex;
  gap: 16rem;
  .img-wrap {
      margin-top: 4rem;
      border: solid 1px $black30;
      border-radius: 16rem;
      width: 50rem;
      height: 50rem;
      margin-bottom: 4rem;
      overflow: hidden; 
      img { 
          width: 100%; 
          max-width:inherit;
          height: 100%; 
          object-fit: cover; 
      }
  }
  .title { 
      font-size: 13rem; 
  }
}

.location-tabs {

    .tabs {
        
        display:flex;
        gap:10rem;
        flex-wrap:wrap;

        .tab { 
            height:34rem;
            display:flex;
            align-items: center;
            justify-content: center;
            padding:6rem 14rem; 
            background:#fff; 
            border:solid 1px $black60; 
            border-radius:20rem;
            font-size:14rem;
            color:$black60;

            &.active {
                background:$purple;
                color:#fff;
                border:solid 1px $purple;
            }

        }
        
    }   
}
.switch-tab {
    display:flex;
    width:calc(100% - 32rem);
    margin:0 auto;
    margin-top:20rem;
    height:42rem;
    border-radius:20rem;
    background:$black20;
    padding:6rem;
    .tab {
        width:100%;
        color:$black80;
        font-size:14rem;

        &.active {
            background:#fff;
            border-radius:30rem;
            font-weight:700;
        }

    }
}
.slide-tabs {
    @include respond(pc) {
        // width: 45% !important;
    }

    @include respond(tablet) {
        width: 100% !important;
    }
    .tabs {
        justify-content: space-around;
        @include respond(pc) {
            justify-content: center;
        }
        @include respond(tablet) {
            padding: 0 30rem !important;
        }
    }

    &.purple2 {
        .tab.active::before {
            background: $yellow100;
        }

        .tab.active {
            color: $yellow100;
        }
    }

    .tab {
        // width: 33.33%;
        width:100%;
    }
}
.additional-category-tabs-wrap {
    position: relative;
    padding: 20rem 20rem 60rem 20rem;
    margin-top: -45rem;
    margin-bottom: 30rem;
    border: 1px solid $black30;
    border-width: 0 1px 1px;
    border-radius: 15rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: #fff;
    z-index: 100;

    li {
        margin-bottom: 20rem;

        a {
            font-size: 14rem;
            color: $black80;
        }
    }
}
.category-after {
    overflow: visible;
    position: relative;

    &::after {
        content: ""; /* 내용 없음 */
        display: block; /* 블록 요소로 표시 */
        position: absolute; /* 부모 요소를 기준으로 절대 위치 설정 */
        top: -31rem; /* 부모 요소의 하단에 위치 */
        left: 0; /* 부모 요소의 왼쪽에 위치 */
        width: 100%; /* 부모 요소의 전체 너비에 맞춤 */
        height: 1px;
        background-color: $black30;
        box-sizing: border-box; /* 패딩과 보더를 요소의 전체 너비와 높이에 포함 */
    }
}
.category-swiper {
    padding-right: 120rem;
    margin-left: 20rem;
}
.additional-category-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 48rem;
    height: 43rem;
    z-index: 10;
    background-color: #fff;
    padding-left: 17rem;
    margin-top: -7rem;

    &::before {
        content: "";
        display: inline-block;
        width: 38rem;
        height: 38rem;
        background-color: #fff;
        position: absolute;
        border-radius: 50%;
        border: 1px solid $black50;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        background: url('../../../images/common/icons/icon_up.svg') no-repeat center;
    }

    &.closed::before {
        background: url('../../../images/common/icons/icon_down.svg') no-repeat center;
    }
}
.additional-swiper {
    .swiper-slide {
        li{
            height: 28rem !important;
            background-color: #fff !important;
            
            button {
                border: 1px solid $black60;
                height: 28rem;
                border-radius: 20rem;
                width: 100%;
            }
        }
    }
}
.ranking-brand-list {

    display:flex;
    flex-direction: column;
    gap:10rem;

    .ranking-brand-wrap {

        display:flex;
        width:100%;
        justify-content: space-between;
        align-items: center;
        padding:0 16rem;
        height:50rem;

        & > a {
            display:flex;
            align-items: center;
            gap:12rem;
            width:100%;

            .brand-index {
                display:inline-block;
                width:20rem;
                font-size:13rem;
            }

            .brand-thumbnail {
                width:50rem;
                height:50rem;
                border-radius:16rem;
                border:solid 1px $black30;
                img {
                    width:100%;
                    height:100%;
                    // object-fit:scale-down;
                    object-fit: cover; 
                    border-radius: inherit; 
                }
            }

            .brand-name {
                font-size:13rem;
            }
        }

        .more-btn {
            width:12rem;
            height:12rem;
            background:url('../../../images/common/icons/icon_small_more.svg') no-repeat center;
            transform:rotate(90deg);

            &.active {
                transform:rotate(-90deg);
            }

        }
    }

    .ranking-brand-detail {
        padding:16rem;
        background:$black10;
    }

}