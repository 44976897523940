.dim {
    width:100vw;
    height:100vh;
    position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background:#000;
    opacity:0.15;
    z-index:998;

    &.white {
        background:none;
        z-index:0;
    }

}

.popup-wrap {
    width:100%;
    max-width: 600rem;
    max-height: 90%;
    margin:0 auto;
    position:fixed;
    bottom:0;
    left:0;
    z-index:998;
    background:#fff;
    border-radius:20rem 20rem 0 0;
    overflow:auto;
    
    @include hideScrollBar();

    @include respond(900) {
        left:50%;
        margin-left:-300rem;
    }
}

.popup-head {
    touch-action: none;
    width:100%;
    height:40rem;
    touch-action: none;
    position:relative;
    &::before {
        content:"";
        display:inline-block;
        width:54rem;
        height:5rem;
        background:$black30;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        border-radius:2rem;
    }
}
.popup-body {
    width:100%;
    background:#fff;
    padding:0 16rem;
}
.popup-footer {
    width:100%;
    background:#fff;
    padding:26rem 16rem;
    position:sticky;
    bottom:0;
    left:0;

    .btn-wrap {
        display:flex;
        gap:10rem;
        .btn {
            width:100%;
            height:46rem;
            color:#fff;
            font-size:16rem;
            border-radius:8rem;
            &.gray { background:$black40; }
            &.purple { background:$purple; }
        }
    }
}

#snackBarPopup {

    bottom:72rem;
    border-radius:0;
    background:none;

    .inner {
        width:calc(100% - 32rem);
        // background:rgba(0,0,0,0.66);
        background:#444;
        padding:12rem 16rem;
        border-radius:8rem;
        display:flex;
        justify-content: space-between;
        .text {
            color:#fff;
            font-size:14rem;
        }
        a {
            color:$purple40;
            font-size:14rem;
            font-weight:700;
        }
    }
}

.section-coupons {
    height:500rem;
    overflow-x:hidden;
    overflow-y:auto;
    @include hideScrollBar;
}