@mixin layout($size) {
    width: calc(100vw - 32rem);
    max-width: #{$size}rem;
	min-width:300rem;
    margin: 0 auto;
}

@mixin iconSize($size) {
	display:inline-block;
    width:#{$size}rem;
    height:#{$size}rem;
    background-size:contain;
	flex-shrink: 0;
}

@mixin hideScrollBar() {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display:none;
	}
}

@mixin ellipse($line) {
    display: -webkit-box;
    -webkit-line-clamp: #{$line};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin respond($breakpoint) {
	@if $breakpoint == mobile {
		@media (max-width: 600px) {
			@content;
		}
	} @else if $breakpoint == tablet {
		@media (min-width: 601px) and (max-width: 1200px) {
			@content;
		}
	} @else if $breakpoint == pc {
		@media (min-width: 1025px) {
			@content;
		}
	} @else {
		@media (min-width : #{$breakpoint}px) {
			@content;
		}
	}
}