#registerPage {

    .title-wrap {
        margin-bottom:16rem;
    }

    .profile-wrap {

        display:flex;
        justify-content:center;
        margin-bottom:30rem;
        
        .img-wrap {
            width:90rem;
            text-align: center;
            p { font-weight:600; margin-top:10rem }
        }
        
    }

}