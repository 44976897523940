.top-bar {

    background:#fff;
    width:100%;
    // max-width:750px;
    height:48rem;
    padding: 14rem 0;
    position:fixed;
    top:0;
    left:50%;
    transform:translateX(-50%);
    z-index:101;
    // max-width:750px;
    &.border {
        border-bottom:solid 1px #D9D9D9;
    }

}

.top-bar-inner {
    @include layout(750);
    max-width:750px;
    display:flex;
    justify-content: space-between;
    gap:16rem;
    align-items: center;

    #logo {
        width:85rem;
        display:flex;
        align-items: center;
        height:100%;
        img {
            width:100%;
        }
    }

    .search-bar {
        width:100%;

        input[type=search] {
            width:100%;
            background:#F2F2F2 url('../../../../images/common/icons/icon_search.svg') no-repeat center left 7rem;
            height:30rem;
            border:none;
            border-radius:30rem;
            padding-left:30rem;
            box-sizing: border-box;
            background-size:16rem;
            font-size:12rem;

            &::placeholder {
                color:#bfbfbf;
                font-size:14rem;
                font-weight:700;
            }

        }
    }

    .actions {
        display:flex;
        gap:15rem;
        align-items: center;
        button {
            position:relative;
        }

        .language-wrap {
            width:20rem;
            height:20rem;
            position:relative;
            display: inherit;
            .tabs {
                display:flex;
                flex-direction: column;
                gap:21rem;
                background:#fff;
                border-radius:8rem;
                width:80rem;
                padding:16rem;
                position:absolute;
                top:24rem;
                left:50%;
                margin-left:-40rem;
                z-index: 1;
                box-shadow: 0 0 4rem rgba(0,0,0,0.25);

                .tab {
                    font-size:14rem;
                    position:relative;
                    &::before {
                        content:"";
                        display:inline-block;
                        width:100%;
                        height:1px;
                        background:$black30;
                        position:absolute;
                        bottom:-11rem;
                        left:0;
                    }
                    &:last-child {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        .icon-notification.active {
            &::before {
                content:"";
                display:inline-block;
                width:3rem;
                height:3rem;
                border-radius:50%;
                background:$purple;
                position:absolute;
                top:0;
                right:0;
                z-index:1;
            }
        }

        .icon-shoppingbag span {
            width:14rem;
            height:14rem;
            border-radius: 50%;
            display:flex;
            align-items: center;
            justify-content: center;
            position:absolute;
            top:-4rem;
            right:-4rem;
            z-index:1;
            font-size:10rem;
            color:#fff;
            background:$purple;
            font-weight:500;
        }
    }

    span.title, a.title {

        font-weight:600;
        display:flex;
        align-items: center;
        gap:10rem;

        & > span {
            display:inline-block;
            white-space: nowrap;
            max-width:50vw;
            min-width:100rem;
            overflow:hidden;
            text-overflow: ellipsis;
        }

        &::before {
            content:"";
            display:inline-block;
            width:20rem;
            height:20rem;
            background:url('../../../../images/common/icons/icon_big_more.svg') no-repeat center;
            background-size:contain;
            transform:rotate(-180deg)
        
        }

        &.reverse {
            &::before {
                display:none;
            }
            &::after {
                content:"";
                display:inline-block;
                width:20rem;
                height:20rem;
                background:url('../../../../images/common/icons/icon_big_more.svg') no-repeat center;
                background-size:contain;
                transform:rotate(0)
            
            }
        }

    }

    div.title {
        width:100%;
        font-weight:600;
        text-align:center;
    }
}