section.section-grid + section.section-grid {
    margin-top:40rem;
}

.grid-wrap {

    @include layout(750);
    max-width:100%;

    .top-wrap {
        display:flex;
        justify-content: space-between;
        padding:30rem 0 10rem;

        h4.title {
            font-size:20rem;
            span {
                font-size:20rem;
            }
        }
        .more-btn {

            &::after {
                content:"";
                display:inline-block;
                width:20rem;
                height:20rem;
                background:url('../../../images/common/icons/icon_big_more.svg') no-repeat center;
                background-size:contain;
                margin-left:5rem;
            }
            
        }
    }

    .grid {

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap:8rem;

        // @include respond(pc) {
        //     grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        //     padding:0 !important;
        // }

        &.grid1 {
            grid-template-columns: 1fr !important;
        }
        &.grid2 {
            
            grid-template-columns: 1fr 1fr !important;
            gap:12rem;

            // @include respond(pc) {
            //     grid-template-columns: 1fr 1fr 1fr 1fr !important;
            //     padding:0 !important;
            // }

        }
        // &.grid3{
        //     height: 400rem !important;

        //     @include respond(pc) {
        //         height: 600rem !important;
        //     }
        // }
        &.no-hide {
            .card {
                display:block !important;
            }
        }
        &.marsonry {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto 1fr;
            grid-gap: 10px;
            .img-wrap,img {height:100%;}
        }

        .img-wrap {
            img {
                border-radius : 8rem;
                width:100%;
                object-fit: cover;
            }
        }

        .info-wrap {

            .brand {
                font-size:12rem;
                font-weight:700;
            }

            .title {
                font-size:13rem;
                margin:3rem 0;
                // height:34rem;
                @include ellipse(2);
            }

            .price-wrap {
                .before-price {
                    .f-purple {
                        font-size:10rem;
                        text-decoration: none !important;
                        font-weight:600;
                        margin-right:3rem;
                    }
                    .price {
                        font-size:10rem;
                        color:$black50;
                        text-decoration: line-through;
                        font-weight:600;
                    }
                }
                .now-price {
                    span {
                        font-size:14rem;
                        font-weight:700;
                    }
                    .f-purple {
                        margin-right:3rem;
                    }
                }
                .badge-wrap {
                    margin-top:3rem;
                }
            }
        }

    }



}

.grid-option-wrap {
    display:flex;
    @include layout(1024);
    max-width:750px;
    margin:0 auto;
    margin-bottom:16rem;
    justify-content: space-between;
    span,b {font-size:14rem;}
    .options { display:flex; gap:16rem; }
    .option {font-size:14rem;}
    .sort-options .option {
        display:flex;
        gap:6rem;
        &::before {
            content:"";
            display:inline-block;
            width:17rem;
            height:17rem;
            background:url('../../../images/common/icons/icon_sort_option.svg') no-repeat center;
            background-size:contain;
        }
    }
    .view-options .option {

        display:inline-block;
        width:17rem;
        height:17rem;

        &.middle {
            background:url('../../../images/common/icons/icon_view_middle.svg') no-repeat center;
            background-size:contain;
        }

        &.big {
            background:url('../../../images/common/icons/icon_view_big.svg') no-repeat center;
            background-size:contain;
        }

    }
}

.scroll-target {
    width:100%;
    height:10rem;
    position:relative;
    .loading-wrap {
        margin-top:120rem;
        position:relative;
        top:-120rem;
        left:50%;
        transform:translateX(-50%);
    }
}
