.banner-wrap {
  &.vw100 {
      width:100vw;
      margin-left:-20rem;
      @include respond(tablet) {
          width: 100%;
          margin-left:0;
      }
      @include respond(pc) {
          width: 100%;
          margin-left:0;
      }
  }
}

.banner {

  width:100%;

  .swiper-slide {

      line-height:0;
      height:100% !important;
      position:relative;

      & > a {
          display:inline-block;
          width:100%;
          height:100%;
          overflow:hidden;
          position:relative;
          line-height:1;
      }
      
      img {
          width:100%;
          height:100%;
          object-fit: cover;
          line-height:1;
      }

  }

  .swiper-pagination {

      z-index:3;
      font-size:12rem;
      color:$black50;
      font-weight:500;
      padding:4rem 8rem;
      background:$black90;
      max-width:50rem;
      border-radius:20rem;
      position:absolute;
      top:auto;
      left:auto;
      right:14rem;
      bottom:16rem;
      white-space:nowrap;

      .swiper-pagination-current {
          font-size:12rem;
          font-weight:700;
          color:#fff;
      }

  }

  .swiper-pagination-total {
      font-size:12rem;
      color:$black50;
      font-weight:500;
  }

  &.banner-a {
      height: 115rem;
  }
  &.banner-b {
      height: 180rem;
  }
  &.banner-c {
      height: 230rem;
  }
  &.banner-d {
      height: 328rem;
  }
  &.banner-e {
      height: 360rem;
  }
  &.banner-f {
      height: 425rem;
  }
  &.banner-g {
      height: 328rem;
      
  }

}

// 히어로 배너
.hero-slide-wrap {

  width:100%;
  max-width:750px;
  height:325rem;

  @include respond(pc) {
      height:440rem;
  }

  img {
    max-height:100%;
  }

  background:#fff;

  .swiper-slide {

      & > a {
          display:inline-block;
          width:100%;
          height:325rem;
          @include respond(pc) {
              height:440rem;
          }
          overflow:hidden;
          line-height:1;
          position:relative;

          &::after {
              content:"";
              display:inline-block;
              width:100%;
              height:100%;
              background:url('../../../images/common/background/bg_hero_slide.svg') no-repeat center;
              position:absolute;
              bottom:0;
              left:50%;
              transform:translateX(-50%);
              z-index:1;
              background-size:cover;
          }
      }

      line-height:0;
      height:100%;
      position:relative;
      
      img {
          width:100%;
          height:100%;
          object-fit: cover;
          line-height:1;
      }

      .text-wrap {
          position:absolute;
          bottom:0;
          left:0;
          z-index:2;
          padding:0 16rem 30rem;
          transition:1.5s;
          opacity:0;

          .title {
              font-size:27rem;
              font-weight:700;
              color:#fff;
              line-height:1.3;
          }
          .text {
              margin-top:10rem;
              font-size:12rem;
              font-weight:600;
              color:#fff;
          }


      }

      &.swiper-slide-active {
          .text-wrap {
              opacity:1;
          }
      }

  }

  .swiper-pagination {

      z-index:3;
      font-size:12rem;
      color:$black50;
      font-weight:500;
      padding:4rem 8rem;
      background:$black90;
      max-width:45rem;
      border-radius:20rem;
      position:absolute;
      top:auto;
      left:auto;
      right:14rem;
      bottom:16rem;

      .swiper-pagination-current {
          font-size:12rem;
          font-weight:700;
          color:#fff;
      }

  }

  .swiper-pagination-total {
      font-size:12rem;
      color:$black50;
      font-weight:500;
  }
}

.brand-hero-banner {
  height:297rem;
  background:$black10;
  margin-bottom:30rem;

  img {
      width:100%;
      height:100%;
      object-fit: cover;
      display: block;
  }
}

.brand-information {
  background-color: $black10;
  padding: 16rem 16rem 32rem 16rem;
  gap: 16rem;

  .tab {
      border: solid 1px #DEDEDE;
      border-radius: 16rem;
      width: 50rem;
      height: 50rem;
      .img-wrap {
        border: solid 1px $black30;
        border-radius: 16rem;
        width: 50rem;
        height: 50rem;
        overflow: hidden; 
        img { 
            width: 100%; 
            max-width:inherit;
            height: 100%; 
            object-fit: cover; 
        }
      }
  }

  .brand-content {
      p {
          font-size: 14rem;
      }
  }
}



