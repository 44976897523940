#payPage {

    // background:$black20;
    padding-bottom:0 !important;

    .section-pay-shopping,
    .section-pay-process {
        padding-bottom:56rem;
    }

    .shopping-list-wrap {
        
        padding:0 16rem;
        background:#fff;

        .all-check-wrap {
            background:#fff;
            padding:20rem 0;
            display:flex;
            justify-content: space-between;
            align-items: center;

            label,a { font-size:14rem; }
        }

        .shopping-list {

            li {
                padding:20rem 0;
                border-top:solid 1px $black40;
            }

            .check-wrap { 
                display:flex; 
                justify-content: space-between;
                margin-bottom:16rem;
                label { display:inline-block; width:100%; max-width:300rem; line-height:1; white-space: nowrap; overflow:hidden; text-overflow: ellipsis; word-break:keep-all; font-size:14rem; }
            }

            .selected-option {
                width:100%;
                height:34rem;
                display:flex;
                align-items: center;
                justify-content: center;
                border-radius:4rem;
                background:$black20;
                font-size:14rem;
                margin-top:10rem;
            }

            .option-wrap {
                display:flex;
                gap:10rem;
                margin-top:10rem;

                .input-box {
                    width:100%;
                }

            }
        }

    }

    .address-wrap {
        margin-top:10rem;
        strong {
            font-size:16rem;
            display:flex;
            gap:10rem;
            align-items: center;
        }
        .user-info {
            margin-bottom:6rem;
            height:24rem;
        }
        .address {
            display:inline-block;
            margin-bottom:10rem;
        }
    }
    
    .section-pay-result {
        .title-wrap {
            font: size 18rem;
            text-align: center;
            margin-bottom:20rem;
        }
        .gray-box {
            background:$black10;
            display:flex;
            flex-direction: column;
            gap:6rem;
            padding:16rem;
            border-radius:4rem;
            .h4-title-wrap {
                width:100%;
                display:flex;
                align-items: center;
                justify-content: space-between;
                .title {margin-bottom:0;}
            }
            .text {
                font-size:14rem;
                color:$black80;
            }
            .f-gray {
                font-size:14rem;
                color:$black50;
            }

        }

    }

}