.input-box {

    position:relative;

    .input {
        position:relative;

        .btn {
            position:absolute;
            top:10rem;
            right:16rem;
            z-index: 1;
    
            &.icon-private {
                top:11rem;
            }
        }
    }

    .file-upload {
        position:relative;
        width:100%;
        height:46rem;
        padding:0 10rem;
        background:#fff;
        border-radius:8rem;
        border:solid 1px #dedede;
        display:flex;
        align-items: center;
        div { 
            width:calc(100% - 100rem);
            color:#999;
            overflow:hidden;
            white-space: nowrap;
            text-overflow:ellipsis;            
        }

        .btn {
            position:absolute;
            top:10rem;
            right:16rem;
            z-index: 1;
    
            &.icon-private {
                top:11rem;
            }
        }
    }

    .title-wrap {
        display:flex;
        justify-content:space-between;
        align-items: center;
    }

    .title {
        display:inline-block;
        margin-bottom:10rem;
        font-weight:600;
    }
    .sub-title {
        display:inline-block;
        font-size:14rem;
        margin-bottom:6rem;
    }

    input[type=text],
    input[type=password],
    input[type=number],
    input[type=email],
    input[type=date],
    select
    {
        display:inline-block;
        width:100%;
        height:46rem;
        padding:0 10rem;
        background:#fff;
        border-radius:8rem;
        border:solid 1px $black30;

        &::placeholder {
            color:$black50;
        }

        &:disabled {
            color:$black50;
        }
    }

    input[type=search] {
        width:100%;
        max-width:100%;
        height:34rem;
        background:$black20;
        border:none;
        border-radius:30rem;
        padding:10rem 16rem;
        font-size:14rem;
        &::placeholder {
            color:$black50;
        }
    }

    .icon-search {
        display:inline-block;
        position:absolute;
        top:50%;
        right:16rem;
        transform: translateY(-50%);
        z-index: 1;
    }

    textarea {
        border-radius:8rem;
        padding:12rem 10rem;
        border:solid 1px $black30;
        &::placeholder { color:$black50; }

        &:disabled {
            color:$black50;
        }
        
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('../../../images/common/icons/icon_under_arr.svg') no-repeat top 13rem right 16rem;
        color:$black90;
        &.selected {
            color:$black;
        }
        option {
            color:$black;
        }
        &.purple {
            height:34rem;
            background: $purple10 url('../../../images/common/icons/icon_under_arr.svg') no-repeat top 11rem right 16rem;
            background-size:12rem 12rem;
            color:$black80;
            line-height:1;
            font-size:14rem;
            font-weight:700;
        }
    }

    .input+.input {
        margin-top:10rem;
    }

    .caption {
        display:inline-block;
        font-size:13rem;
        color:$purple;
    }

    & + .input-box {
        margin-top:30rem;
    }

    .inner-text {
        position:absolute;
        top:13.5rem;
        right:16rem;
        z-index:1;
        color:$black80;
        line-height:1;
    }

    &.textarea {
        display:flex;
        flex-direction: column;
    }

    .option-select{
        display:inline-block;
        width:100%;
        margin-top: 2rem;
        height:30rem;
        padding:0 60rem 0 10rem;
        background:#fff;
        border-radius:8rem;
        border:solid 1px $black30;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('../../../images/common/icons/icon_under_down.svg') no-repeat top 8rem right 10rem;
        color:$black;
        font-size: 14rem;

        @include respond(tablet) {
            background: url('../../../images/common/icons/icon_under_down.svg') no-repeat top 10rem right 10rem;
        }
        
        @include respond(pc) {
            background: url('../../../images/common/icons/icon_under_down.svg') no-repeat top 10rem right 10rem;
        }

        &::placeholder {
            color:$black50;
        }

        &:disabled {
            color:$black50;
        }

        &.selected {
            color:$black;
        }
        option {
            color:$black;
        }
        &.purple {
            height:34rem;
            background: $purple10 url('../../../images/common/icons/icon_under_down.svg') no-repeat top 11rem right 16rem;
            background-size:12rem 12rem;
            color:$black80;
            line-height:1;
            font-size:14rem;
            font-weight:700;
        }


    }
}

.number-spinner {

    border:solid 1px $black40;
    display: flex;
    justify-content: space-between;
    padding:0 10rem;
    width: 100%;
    height:34rem;
    
    button {
        font-size:24rem;
        font-weight:200;
        line-height: 1;
    }

    input {
        width:40rem;
        border:none;
        text-align: center;
        line-height:1;
        background:none;
        font-size:14rem;
    }

    &.purple {
        background:$purple10;
        border-radius: 8rem;
        border:none;
        input { font-weight:700; }
    }

}

.input-box-wrap {

    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:20rem;

    & .input-box {
        margin-top:0;
    }

}

.input-box + .input-box-wrap,
.input-box-wrap + .input-box-wrap
{
    margin-top:30rem;
}

.input.check {

    position:relative;
    padding-left:24rem;

    label {

        font-size:14rem;

        -webkit-user-select: none; /* Safari */
        -moz-user-select: none;    /* Firefox */
        -ms-user-select: none;     /* Internet Explorer/Edge */
        user-select: none;         /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
        cursor:pointer;
    }

    input[type=checkbox] {

        position:absolute;
        top:0;
        left:0;
        width:19rem;
        height:19rem;
        
        &::before {
            content:"";
            display:inline-block;
            width:20rem;
            height:20rem;
            background:#fff url('../../../images/common/icons/icon_check.svg') no-repeat center;
            background-size:contain !important;
    
            position:absolute;
            top:50%;
            left:0;
            transform:translateY(-50%);
        }
    
        &:checked::before {
            background:#fff url('../../../images/common/icons/icon_check_active.svg') no-repeat center;
            background-size:contain !important;
        }
    
    }

    &.bg {

        padding-left:30rem;

        label {
            // font-weight:600;
            font-size:16rem;
        }

        input[type=checkbox] {

            width:22rem;
            height:21rem;

            &::before {
                content:"";
                display:inline-block;
                width:24rem;
                height:24rem;
                background:#fff url('../../../images/common/icons/icon_check_bg.svg') no-repeat center !important;
                background-size:contain !important;
            }
    
            &:checked::before {
                background:#fff url('../../../images/common/icons/icon_check_bg_active.svg') no-repeat center !important;
                background-size:contain !important;
            }

        }
    }

}

.input.innercheck {

    position:relative;
    padding-left:24rem;

    label {

        font-size:14rem;

        -webkit-user-select: none; /* Safari */
        -moz-user-select: none;    /* Firefox */
        -ms-user-select: none;     /* Internet Explorer/Edge */
        user-select: none;         /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
        cursor:pointer;
    }

    input[type=checkbox] {

        position:absolute;
        top:9rem;
        left:9rem;
        width:19rem;
        height:19rem;
        
        &::before {
            content:"";
            display:inline-block;
            width:20rem;
            height:20rem;
            background: url('../../../images/common/icons/icon_check.svg') no-repeat center;
            background-size:contain !important;
    
            position:absolute;
            top:50%;
            left:0;
            transform:translateY(-50%);
            z-index: 100;
        }
    
        &:checked::before {
            background: url('../../../images/common/icons/icon_check_active.svg') no-repeat center;
            background-size:contain !important;
        }
    
    }

    &.bg {

        padding-left:30rem;

        label {
            // font-weight:600;
            font-size:16rem;
        }

        input[type=checkbox] {

            width:22rem;
            height:21rem;

            &::before {
                content:"";
                display:inline-block;
                width:24rem;
                height:24rem;
                background: url('../../../images/common/icons/icon_check_bg.svg') no-repeat center !important;
                background-size:contain !important;
            }
    
            &:checked::before {
                background: url('../../../images/common/icons/icon_check_bg_active.svg') no-repeat center !important;
                background-size:contain !important;
            }

        }
    }

}

.input.radio {

    position:relative;
    padding-left:30rem;

    label {

        -webkit-user-select: none; /* Safari */
        -moz-user-select: none;    /* Firefox */
        -ms-user-select: none;     /* Internet Explorer/Edge */
        user-select: none;         /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
        cursor:pointer;
    }

    input[type=radio] {
        position:absolute;
        top:-1px;
        left:0;
    }

    &.etc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left:0;
        gap:10rem;
        label {
            padding-left:30rem;
        }

    }

}

textarea {
    width:100%;
    min-height:120rem;
    &::placeholder {color:$black50;}
}

input[type=radio] {
    cursor:pointer;
    position:relative;
    width:23rem;
    height:23rem;
    
    &::before {
        content:"";
        display:inline-block;
        width:24rem;
        height:24rem;
        background:#fff url('../../../images/common/icons/icon_radio.svg') no-repeat center;
        background-size:contain;

        position:absolute;
        top:50%;
        left:0;
        transform:translateY(-50%);
    }

    &:checked::before {
        background:#fff url('../../../images/common/icons/icon_radio_active.svg') no-repeat center;
        background-size:contain;
    }

}

input[type=checkbox] {
    cursor:pointer;
    position:relative;
    width:19rem;
    height:19rem;
    
    &::before {
        content:"";
        display:inline-block;
        width:20rem;
        height:20rem;
        background:#fff url('../../../images/common/icons/icon_check.svg') no-repeat center;
        background-size:contain;

        position:absolute;
        top:50%;
        left:0;
        transform:translateY(-50%);
    }

    &:checked::before {
        background:#fff url('../../../images/common/icons/icon_check_active.svg') no-repeat center;
        background-size:contain;
    }

}

input[type=checkbox].bg {

    width:23rem;
    height:23rem;

    &::before {
        content:"";
        display:inline-block;
        width:24rem;
        height:24rem;
        background:#fff url('../../../images/common/icons/icon_check_bg.svg') no-repeat center;
        background-size:contain;
    }

    &:checked::before {
        background:#fff url('../../../images/common/icons/icon_check_bg_active.svg') no-repeat center;
        background-size:contain;
    }

}


.input-list {

    display:flex;
    flex-direction: column;
    gap:14rem;

    &.row {
        flex-direction: row;
        gap:20rem;
        flex-wrap:wrap;
    }

    .input { 

        margin-top:0 !important; 
        display:flex;
        justify-content: space-between;
        align-items: center;

        .under-line {
            font-size:14rem;
        }

    }
}

.check-list {

    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:20rem;

    li {

        height:64rem;
        display:flex;

        label {

            display:flex;
            width:100%;
            height:100%;
            justify-content:space-between;
            align-items:center;
            background:#fff;
            padding:0 16rem;
            border-radius:8rem;
            outline:solid 1px $black40;
            font-weight:600;
            cursor: pointer;

            &.active {
                outline:solid 2rem $purple;
                background:$purple10;

                input[type=checkbox].bg::before {
                    background:$purple10 url('../../../images/common/icons/icon_check_bg.svg') no-repeat center;
                    background-size:contain;
                }

                input[type=checkbox].bg:checked::before {
                    background:$purple10 url('../../../images/common/icons/icon_check_bg_active.svg') no-repeat center;
                    background-size:contain;
                }
            }

            &.disabled {
                outline:solid 1px $black40;
                background : $black10;
                pointer-events: none;
            }

        }

    }

    &.color {
        
        grid-template-columns: 1fr 1fr 1fr;
        gap:16rem;

        li {

            height:48rem;
            
            label {

                justify-content: flex-start; 
                gap:9rem;
                white-space: nowrap;

                &::before {
                    content:"";
                    display:inline-block;
                    width:20rem;
                    height:20rem;
                    border-radius:50%;
                    background:#000;
                    flex-shrink: 0;
                    box-sizing: border-box;
                }
            }

            &.grey { label::before { background:$black40; } }
            &.white { label::before { background:#fff; border:solid 1px $black90; } }
            &.navy { label::before { background:#031972; } }
            &.blue { label::before { background:#214EF6; } }
            &.sky { label::before { background:#C2DFFF; border:solid 1px #fff; } }
            &.pink { label::before { background:#E9A0CA; } }
            &.yellow { label::before { background:#FBEE3F; } }
            &.lime { label::before { background:#BFF940; } }
            &.green { label::before { background:#698E39; } }
            &.brown { label::before { background:#684814; } }
            &.beige { label::before { background:#FCF4DA; } }
            &.purple { label::before { background:#773EEA; } }
            &.orange { label::before { background:#E99B2B; } }
            &.red { label::before { background:#DD4319; } }

            input[type=checkbox] { display: none; }



        }

    }

    &.brand {

        gap:16rem;

        li {
            height:80rem;
        }

    }

    &.no-input {

        grid-template-columns: 1fr 1fr 1fr;
        gap:16rem;

        label {
            justify-content: center;
            input { display:none; }
        }

    }

    &.vendor {
        li {
            height:auto;
        }
        label {
            box-sizing: border-box;
            padding:10rem 16rem;
            flex-direction: column;
            font-size:24rem;
        }
        p {
            margin-top:10rem;
            font-weight:400;
        }
    }



}   
