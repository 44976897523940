.card {

    position:relative;
    .before-price,.now-price { line-height:1; }
    .badge {padding:4rem 6rem}

    .img-wrap {
        position:relative;
        & > a {
            line-height:0;
        }
        .icon-heart {
            position:absolute;
            bottom:6rem;
            right:6rem;
            z-index:1;
            width:16rem;
            height:16rem;
        }
    }

    &.small {

        .img-wrap {

            img {
                border-radius : 8rem;
                width:100%;
                object-fit: cover;
                height:132rem;
                @include respond(pc) {
                    height:148rem;
                }
            }
        }

        .info-wrap {

            .brand {
                font-size:12rem;
                font-weight:700;
                margin-top:6rem;
            }

            .title {
                margin:0 !important;
                font-size:13rem;
                font-weight:400;
                // height:34rem;
                @include ellipse(2);
            }

            .price-wrap {
                .before-price {
                    .f-purple {
                        font-size:10rem;
                        text-decoration: none !important;
                        font-weight:600;
                        margin-right:3rem;
                    }
                    .price {
                        font-size:10rem;
                        color:$black50;
                        text-decoration: line-through;
                        font-weight:600;
                    }
                }
                .now-price {
                    span {
                        font-size:14rem;
                        font-weight:700;
                    }
                    .f-purple {
                        margin-right:3rem;
                    }
                }
                .badge-wrap {
                    margin-top:3rem;
                }
            }
        }
    }

    &.middle {
        .card-inner {
            display:flex;
            gap:10rem;
            .img-wrap {
                width:110rem;
                height:140rem;
                flex-shrink: 0;
                img {
                    height:100%;
                    border-radius:8rem;
                }
            }
        }
    }

    &.big {
        .img-wrap {
            height:192rem;

            @include respond(pc) {
                height:250rem;
            }

            img {
                height:100%;
            }
        }
        .brand {
            margin-top:10rem;
        }
    }

    &.very-small {
        display:flex;
        align-items: center;
        gap:10rem;
        .img-wrap {
            width:60rem;
            height:60rem;
        }
    }

    &.brand {
        gap:12rem;
        .img-wrap {
            height:195rem;
            img {height:100%;}
        }
        .brand {
            margin-top:10rem;
        }
        .title {
            margin:3rem 0;;
        }
    }

    &.normal {
        height:104rem !important;
    }

    &.large {
        grid-column: 1 / -1;
        grid-row: 1; 
        height:170rem !important;
    }

    &.hot-brand {
        .brand-title-wrap {

            display:flex;
            gap:10rem;
            align-items: center;
            margin-bottom:20rem;

            .img-wrap {
                width:50rem;
                height:50rem;
                border:solid 1px $black30;
                border-radius:16rem;
                text-align: center;
                img { width:100%; max-width:40rem; height:100%; object-fit: contain;}
            }
        }
        .grid-wrap {

            a { 
                display:inline-block;
                width:100%;
                height:100%;
            }
            img {
                border-radius:8rem;
                line-height:0;
            }
        }
        & + &.hot-brand {
            margin-top:30rem;
        }
    }

    &.review {

        display:flex;
        gap:16rem;

        & + .review {
            margin-top:20rem;
        }

        .img-wrap {
            width:120rem;
            flex-shrink: 0;
            border-radius:8rem;
            overflow: hidden;
        }
    
        .review-wrap {
            
            width:100%;
            padding:10rem 0;
            position:relative;
            display:flex;
            justify-content: space-between;

            .text-wrap {
                p { font-size:14rem; color:$black80; }
            }
            .btn {
                width:98rem;
                height:34rem;
                font-size:14rem;
                position:absolute;
                bottom:10rem;
                right:0;
            }
        }




    }

    &.review-detail {
        .stars-wrap {
            display:flex;
            justify-content: space-between;
            margin-bottom:12rem;
            .date {font-size:14rem; }
        }
        .text-wrap {
            strong { font-size:14rem; }
            ul {
                display:flex;
                flex-wrap:wrap;
                gap:6rem 16rem;
                span { font-size:14rem; }
                .value {
                    margin-left:6rem;
                }
            }
        }
    }

    &.order {
        display:flex;
        gap:10rem;

        .img-wrap {
            width:100rem;
            border-radius:8rem;
            overflow:hidden;
        }
        .text-wrap {
            margin-top:2rem;

            .date,.brand,.name { font-size: 12rem; color:$black80; }
            .brand { font-weight:700; margin:6rem 0; }
            .price { font-size: 14rem; margin-top:6rem; color:$black80; font-weight:700; }

        }
    }

    &.kid {

        padding:16rem;
        border-radius:10rem;
        display:flex;
        gap:16rem;

        &.bg { background:#f8f8f8; }
        // &.star { background:#f8f8f8; }

        &.active { background:$purple10; }

        .img-wrap {
            width:60rem;
            height:60rem;
            position:relative;
            flex-shrink: 0;

            .badge {
                position:absolute;
                bottom:-11rem;
                left:50%;
                transform:translateX(-50%);
            }
        }

        .text-wrap {

            position:relative;
            width:100%;

            .title-wrap {
                display:flex;
                justify-content: space-between;
                align-items: center;
                .title { font-size:18rem !important; margin-bottom:0 !important; font-weight:700; }
                .span-wrap {
                    display:flex;
                    gap:10rem;
                    span { font-size: 12rem; }
                }
            }

            .body-list {
                display:grid;
                grid-template-columns: 1fr 1fr;
                gap:6rem 10rem;
                max-width:190rem;
                margin-top:6rem;
                li {
                    display:flex;
                    justify-content: space-between;
                    span { font-size:13rem; color:$black60; }
                    b { font-size:13rem; color:$black80; }
                }
            }
        }

    }

    &.shopping {

        display:flex;
        gap:10rem;
        // align-items: center;

        .img-wrap {
            width:100rem;
            height:100rem;
            flex-shrink: 0;
            border-radius:8rem;
            overflow:hidden;
            img { width:100%; height:100%; object-fit: cover;}
        }

        .info-wrap {

            .order-number {
                font-size:10rem;
                color:$black80;
                display:block;
                width:100%;
                width:140rem;
                white-space: nowrap;
                overflow:hidden;
                text-overflow: ellipsis;
            }
            .title {
                font-size:12rem;
                font-weight:700;
                color:$black80;
                line-height:1;
                margin-bottom:6rem;
            }
            .now-price {
                font-size:14rem;
                font-weight:700;
                line-height:1;
                margin-bottom:6rem;
            }
            .after-price {
                font-size:12rem;
                font-weight:600;
                line-height:1;
                margin-bottom:6rem;
            }
        }

        & + .shopping {
            margin-top:10rem;
        }

    }

    &.order2 {

        display:flex;
        gap:10rem;
        flex-direction: column;

        .img-wrap {
            width:100rem;
            height:100rem;
            flex-shrink: 0;
            // border-radius:8rem;
            overflow:hidden;
            img { width:100%; height:100%; object-fit: cover;}
        }

        .order-number-wrap {

            .order-number {
                font-size:14rem;
                color:$black80;
                display:block;
                width:60%;
                white-space: nowrap;
                overflow:hidden;
                text-overflow: ellipsis;
            }
            .title {
                font-size:12rem;
                font-weight:700;
                color:$black80;
                line-height:1;
                margin-bottom:6rem;
            }
            .now-price {
                font-size:14rem;
                font-weight:700;
                line-height:1;
                margin-bottom:6rem;
            }
            .after-price {
                font-size:12rem;
                font-weight:600;
                line-height:1;
                margin-bottom:6rem;
            }
        }

        & + .shopping {
            margin-top:10rem;
        }

    }

    &.delivery {

        &.delivery + .delivery {
            margin-top:40rem;
        }

        .name-wrap {
            display:flex;
            align-items: center;
            gap:10rem;
            position:relative;
            .badge {
                padding:7rem 6rem;
            }

            .btn.purple {
                position:absolute;
                top:0;
                right:0;
                border-radius:20rem;
                height:34rem;
                padding:0 14rem;
                font-weight:400;
            }
        }

        .info-wrap {
            font-size:14rem;
            margin:6rem 0 2rem;
        }

        .address-wrap {
            font-size:14rem;
            color:$black50;
        }
        .btn-wrap {
            display:flex;
            justify-content: flex-end;
            a,button {
                font-size:12rem;
                color:$black50;
                &:first-child::after {
                    content:"";
                    display:inline-block;
                    width:1px;
                    height:12rem;
                    background:$black50;
                    margin:0 0 0 10rem;
                    position:relative;
                    top:2rem;
                }
            }
        }
    }

    &.item {
        padding:16rem;
        background:$black10;
        position:relative;
        &::after {
            content:"";
            display: inline-block;
            width:calc(100% - 32rem);
            height:1px;
            background:$black30;
            position:absolute;
            bottom:0;
            left:50%;
            transform:translateX(-50%);
        }

        &:first-child {
            border-radius:8rem 8rem 0 0;
        }
        &:last-child {
            border-radius:0 0 8rem 8rem;
            &::after { display:none; }
        }

        .top {
            display:flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom:6rem;
        }
        .bottom {
            display:flex;
            align-items: center;
            justify-content: space-between;
        }
        p { font-size:14rem; }
    }
    

    &.review2 {
        display:flex;
        flex-direction: column;
        margin-bottom:24rem;
        padding:0 16rem;
        .profile-wrap {
            display:flex;
            align-items: center;
            gap: 10rem;
            margin-bottom:6rem;
            position:relative;

            .img-wrap {
                width:30rem;
                height:30rem;
                border-radius: 50%;
                overflow: hidden;
                img { width:100%; height:100%; object-fit: cover; }
            }

            .status {
                position:absolute;
                top:6rem;
                right:90rem;
                font-size:12rem;
                color:$black90;
                font-weight: bold;
                &::after {
                    content:"";
                    display:inline-block;
                    width:1px;
                    height:12rem;
                    background:$black50;
                    margin-left:10rem;
                    position:relative;
                    top:2rem;
                }
            }
            
            .answer-status {
                position:absolute;
                top:6rem;
                right:85rem;
                font-size:12rem;
                color: $purple;
                font-weight: bold;
                &::after {
                    content:"";
                    display:inline-block;
                    width:1px;
                    height:12rem;
                    background:$black50;
                    margin-left:10rem;
                    position:relative;
                    top:2rem;
                }
            }

            .date {
                position:absolute;
                top:5rem;
                right:0;
                color:$black80;
                font-size:14rem;
                font-weight:700;
            }


        }

        .stars {
            margin-bottom:10rem;
        }

        .review {
            .img-wrap {
                width: 100%;
                height: 100%;
                border-radius:4rem;
                overflow: hidden;
                margin-bottom:10rem;
                img { width:100%; height:100%; object-fit: cover; }
            }

        }

        & + .review2 {
            padding-top:20rem;
            border-top:solid 8rem $black20;
        }

    }

    &.review3 {
        display:flex;
        justify-content: space-between;
        gap:10rem;
        .img-wrap {
            display:flex;
            img {
                width:100rem;
                height:100rem;
                border-radius:4rem;
            }
        }
    }

    &.inquiry {
        border-top: solid 1px $black30;
        // border-bottom: solid 1px $black30;
        padding:16rem 0;
        .text {
            font-size:13rem;
            margin-bottom:10rem;
        }
        .status-wrap {

            span { 
                font-size:12rem;
                color:$black60;
                &.status { font-weight:700; font-size:12rem; color:$black90; }
                &::after {
                    content:"";
                    display:inline-block;
                    width:1px;
                    height:12rem;
                    background:$black50;
                    margin:0 10rem;
                }
            }
        }
    }

    &.coupon {
        border:solid 1px $black40;
        padding:16rem;
        border-radius:8rem;

        .top-wrap {
            display:flex;
            justify-content: space-between;
            margin-bottom:6rem;
            .btn { font-size:14rem; height:34rem; padding: 10rem; }
            strong {
                font-size:20rem;
                height:34rem;
            }
        }
        .bottom-wrap {
            .text {
                font-size:14rem;
                color:$black70;
                margin-bottom:2rem;
                height:40rem;
                @include ellipse(2);
            }
            .d-day {
                display:flex;
                font-size:14rem;
                gap:7rem;
                flex-direction: column;
                .caption {
                    font-size:14rem;
                    color:$black70;
                    font-weight:400;
                }
            }
        }

    }

    &.answer {
        background: $black20;
        width: 100%;
        // height: 30rem;
        padding: 20rem 16rem;
    
    }

}

