.graph-box {

    .info-wrap {

        display:flex;
        justify-content: space-between;

        .title {
            display:flex !important;
            gap:6rem;
            align-items: center;
            font-size:12rem !important;
            color:$black80 !important;
            span.yellow {
                display:flex;
                align-items: center;
                justify-content: center;
                font-size:12rem;
                color:$black80;
                font-weight:700;
                height:22rem;
                padding:0 12rem;
                background:$yellow;
                border-radius:30rem;
            }
        }

        .infos {
            display:flex;
            gap:16rem;
            .info {
                display:flex;
                gap:4rem;
                font-size:12rem;
                color:$black80;
                &::before {
                    content:"";
                    display:inline-block;
                    width:16rem;
                    height:16rem;
                    background:$purple;
                    border-radius: 50%;
                }
                &.yellow {
                    &::before {
                        content:"";
                        display:inline-block;
                        width:16rem;
                        height:16rem;
                        background:$yellow;
                        border-radius: 50%;
                    }
                }
            }
        }

    }
    .graphs {

        width:100%;
        height:144rem;
        display:flex;
        justify-content: center;
        align-items:flex-end;

        .graph {

            display:flex;
            flex-direction: column;
            align-items: center;
            width:33%;
            position:relative;

            &::before {
                content:"";
                display:inline-block;
                width:100%;
                height:1px;
                background:$black30;
                position:absolute;
                bottom:22rem;
                left: 50%;
                transform:translateX(-50%);
            }

            p { font-size:12rem; color:$black80; margin-top:6rem; }

            .bars {

                display:flex;
                gap:10rem;
                align-items: flex-end;

                .bar {
                    width:24rem;
                    height:80rem;
                    background:$purple;
                &.yellow {
                    height:100rem;
                    background:$yellow;
                }
                }
                
            }

        }
    }

    & + .graph-box {
        margin-top:30rem;
    }

}
.purple-box {
    display:flex;
    align-items: center;
    justify-content: center;
    gap:8rem;
    width:100%;
    padding:10rem;
    background:$purple10;
    border-radius:4rem;
    font-size:13rem;
    color:$black80;
    b {
        font-size:18rem;
    }
}
.order-list {
    display:flex;
    gap:6rem;
    flex-direction: column;
    padding:16rem 0 20rem;
    li { 
        display:flex;
        justify-content: space-between;
    }
    span,b { font-size:14rem ;}
}
.section-coupons {
.inner {
    &> .title {
        display: inline-block;
        margin:0 0 16rem;
        font-size:14rem;
        b {font-size:14rem;}
    }

    .coupons-list {

        display:flex;
        gap:20rem;
        flex-direction: column;

    }

} 
}

.review-option-wrap {
    .options {
        display:flex;
        .option {

            color:$black80;
            font-size:14rem;
            display:flex;
            align-items: center;

            &.active {
                &::before {
                    content:"";
                    display: inline-block;
                    width:16rem;
                    height:16rem;
                    background:url('../../../images/common/icons/icon_sort_option.svg');
                    margin-right:4rem;
                    background-size:cover;
                }
            }
            
            &:first-child {
                &::after {
                    content:"";
                    display: inline-block;
                    width:1px;
                    height:12rem;
                    background:$black50;
                    margin:0 10rem;
                    position: relative;
                    top:0;
                }
            }
        }
    }
}

.toggle-list {
    li > button.q {
        padding:16rem;
        display:flex;
        align-items: center;
        gap:20rem;
        width: 100%;
        .title-wrap {
            display:flex;
            align-items: center;
            gap:8rem;
            .badge {
                flex-shrink: 0;
                height:14rem;
                color:#fff;
                background:$purple;
                font-size:12rem;
            }
            .title {
                text-align: left;
                font-size:14rem;
                color:$black80;
                @include ellipse(2);
            }
        }
        .date {
            color:$black50;
            font-size:14rem;
            white-space: nowrap;
        }
    }
    li > button.a {
        text-align: left;
        background:$black20;
        padding:20rem 16rem;
        line-height:1.5;
        font-size:14rem;
        width: 100%;
    }
    li + li {
        border-top:solid 1px $black30;
    }
    li:last-child {
        border-bottom:solid 1px $black30;
    }
}

.alert-list {
    li > button.q {
        padding:16rem;
        display:flex;
        justify-content: space-between;
        align-items: center;
        gap:20rem;
        width: 100%;
        max-width: 530rem;
        margin: 0 auto;

        .title-wrap {
            display: flex;
            align-items: center;
            gap:8rem;
            .badge {
                flex-shrink: 0;
                height:14rem;
                color:#fff;
                background:$purple;
                font-size:11rem;
            }
            .title {
                text-align: left;
                font-size:14rem;
                @include ellipse(1);
            }
            .is-active-false {
                color:$black50;
            }
            .date {
                color:$black50;
                font-size:14rem;
                white-space: nowrap;
            }
        }
    }
    li > button.a {
        text-align: left;
        background:$black20;
        padding:20rem 16rem;
        line-height:1.5;
        font-size:14rem;
        width: 100%;
    }
    li + li {
        border-top:solid 1px $black30;
    }
    li:last-child {
        border-bottom:solid 1px $black30;
    }
}

.order-info-wrap {
    max-width:750px;
    margin:0 auto;
    padding:16rem 16rem 20rem;
    border-bottom:solid 8rem $black20;
    .order-info-list {
        display:flex;
        gap:11rem;
        &>li {
            width:25%;
            display:flex;
            padding:16rem 4rem;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap:6rem;
            border-radius:8rem;
            background:$black10;
            strong { font-size:20rem; }
            p { font-size:13rem; color:$black80; font-weight:700;}
        }
    }
    .order-tabs {
        height:42rem;
        padding:6rem;
        background:$black20;
        border-radius:20rem;
        margin:16rem 0 0;
        display:flex;
        gap:6rem;
        justify-content: space-between;
        .tab {
            width:25%;
            font-size:14rem;
            font-weight:700;
            &.active {
                background:#fff;
                border-radius:30rem;
            }
        }
    }
}

.order-list-wrap {
    background:#fff;
    .order-list {

        padding-top:0;

        li {
            padding: 20rem 16rem;
            border-bottom:solid 8rem $black20;

            &:last-child { border-bottom:none; padding-bottom:0; }

            .order-number-wrap {
                display:flex;
                justify-content: space-between;
                margin-bottom:10rem;

                .order-number,.more-btn {
                    font-size:14rem;
                    color:$black80;
                }
                
                .more-btn {
                    display:flex;
                    gap:4rem;
                    align-items:center;
                    &::after {
                        content:"";
                        display:inline-block;
                        width:12rem;
                        height:12rem;
                        background:url('../../../images/common/icons/icon_more_arr.svg');
                        background-size:contain;
                    }
                }
            }

            .status-wrap {
                display:flex;
                justify-content: space-between;
                margin-bottom:10rem;
                .status {
                    color:$black80;
                }
                .caption {
                    font-size:12rem;
                    color:$black80;
                    display:inline-block;
                }
            }

            .item-info-wrap {
                
                display:flex;
                gap:10rem;
                margin-top:10rem;

                .img-wrap {
                    width:100rem;
                    max-height:100rem;
                    // border-radius:8rem;
                    overflow:hidden;
                }
                .text-wrap {
                    margin-top:2rem;

                    .date,.brand,.name { font-size: 12rem; color:$black80; }
                    .brand { font-weight:700; margin:6rem 0; }
                    .price { font-size: 14rem; margin-top:6rem; color:$black80; font-weight:700; }

                }


            }
        }
    }
}


$offset: 187;
$duration: 1.4s;

.loading-wrap {
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:140rem;
}

.spinner {
    width:40rem;
    height:40rem;
    animation: rotator $duration linear infinite;
}

.colors-wrap {

    margin-top:10rem;

    .colors {

        display:flex;
        gap:8rem;
        flex-wrap:wrap;

        .color {

            width:14rem;
            height:14rem;

            & > button {

                width:14rem;
                height:14rem;
                border-radius:50%;
                background:black;
                
                &:hover {
                    outline:solid 2px $purple;
                }

                &.none, &.None { 
                    background:#fff; 
                    border:solid 1px $black90;
                    position:relative;
                    &::before {
                        content:"";
                        display:inline-block;
                        width:1px;
                        height:100%;
                        position:absolute;
                        top:50%;
                        left:50%;
                        transform:translate(-50%,-50%) rotate(-45deg);
                        z-index: 1;
                        background:$black90;
                    }
                }
                &.Gray, &.Grey, &.gray, &.grey { background:$black40 }
                &.White, &.white { background:#fff; border:solid 1px $black90; }
                &.Navy, &.navy { background:#031972; }
                &.Blue, &.blue { background:#214EF6; }
                &.Sky, &.sky { background:#C2DFFF; border:solid 1px #fff; }
                &.Pink, &.pink { background:#E9A0CA; }
                &.Yellow, &.yellow { background:#FBEE3F; }
                &.Lime, &.lime { background:#BFF940; }
                &.Green, &.green { background:#698E39; }
                &.Brown, &.brown { background:#684814; }
                &.Beige, &.beige { background:#FCF4DA; }
                &.Purple, &.purple { background:#773EEA; }
                &.Orange, &.orange { background:#E99B2B; }
                &.Red, &.red { background:#DD4319; }

            }
        }

    }

}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite, 
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
    0% { stroke: $purple; }
	// 25% { stroke: #DE3E35; }
	// 50% { stroke: #F7C223; }
	// 75% { stroke: #1B9A59; }
  100% { stroke: $yellow; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: $offset/4;
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}

.gray-box {
    background:$black20;
    padding:10rem;
    border-radius:8rem;
}

.table-wrap {
    table {
        width:100%;
        border-collapse: collapse;
        th {
            background:$black20;
            padding:10rem;
            border:solid 1px $black90;
        }
        td {
            text-align: center;
            padding:10rem;
            border:solid 1px $black90;
            background:#fff;
            &.text-left {
                text-align: left;
            }
        }
    }
}