#homePage {

    .scroll-target {
        .loading-wrap {
            display:none;
        }
    }

    .section-login {
        padding:20rem 0 0;
        background:#fff;
        max-width:750px;
        margin:0 auto;

        .inner {
      
          background:#f8f8f8;
          border-radius:10rem;
          padding:20rem 16rem 24rem;
      
          .profile-wrap {
      
              width:100%;
              display:flex;
              align-items: center;
              justify-content: space-between;
              gap:12rem;      
      
              .img-wrap {
                  width:60rem;
                  height:60rem;
                  border-radius:50%;
                  flex-shrink: 0;
              }
              .text-wrap {
      
                  width:100%;
                  font-size:18rem;
                  font-weight:500;
                  
                  b { font-size:18rem; }
              
              }
       
      
      
          }
      
          .banner-coupon {
      
              display: flex;
              align-items: center;
              background:#fff;
              border:solid 1px #ccc;
              border-radius: 8rem;
              padding:12rem;
              margin-top:16rem;
              position:relative;
      
              .img-wrap {
                  width:36rem;
                  height:36rem;
                  background:$purple40 url('../../../../images/common/icons/icon_gift.svg') no-repeat center;
                  background-size:24rem 24rem;
                  flex-shrink: 0;
                  border-radius: 50%;
                  margin-right:8rem;
              }
      
              .text-wrap {
                  font-size:13rem;
                  span,b {
                      font-size:13rem;
                  }
              }
      
              .btn {
                  position:absolute;
                  top:50%;
                  transform:translateY(-50%);
                  right:12rem;
                  border-radius:2rem;
                  font-weight:600;
              }
      
          }
      
          .size-wrap {
              margin-top:20rem;
              .title-wrap {
                  display:flex;
                  justify-content: space-between;
                  font-weight:700;
                  margin-bottom:10rem;
              }
              .white-box {
                  display:flex;
                  justify-content: center;
                  gap:32rem;
                  padding:14rem;
                  background:#fff;
                  border-radius:4rem;
                  box-shadow:0 0 3rem rgba(0,0,0,0.25);
      
                  .item {
                      text-align: center;
                      // padding-top:20rem;
                      .key {
                          margin-top:2rem;
                          font-size:12rem;
                          color:$black70;
                          line-height:22rem;
                          margin-bottom:2rem;
                      }
                      .value {
                          font-size:14rem;
                          font-weight:700;
                          color:$black80;
                          letter-spacing: -0.04em;
                      }
                      &::before {
                          content:"";
                          display:inline-block;
                          width:20rem;
                          height:20rem;
                      }
      
                      &:nth-child(1)::before {
                          background:url('../../../../images/common/icons/icon_height.svg') no-repeat center;
                          background-size:contain;
                      }
                      &:nth-child(2)::before {
                          background:url('../../../../images/common/icons/icon_weight.svg') no-repeat center;
                          background-size:contain;
                      }
                      &:nth-child(3)::before {
                          background:url('../../../../images/common/icons/icon_head.svg') no-repeat center;
                          background-size:contain;
                      }
                      &:nth-child(4)::before {
                          background:url('../../../../images/common/icons/icon_shoe.svg') no-repeat center;
                          background-size:contain;
                      }
                  }
              }
      
          }
      
        }

    }

    // .grid2 {
            
    //     .card:nth-child(n+3) {

    //         display:none;

    //         @include respond(pc) {
    //             display:block;
    //         }

    //     }
    // }
    
    // .grid3 {
        
    //     .card:nth-child(n+4) {

    //         display:none;

    //         @include respond(pc) {
    //             display:block;
    //         }

    //     }

    // }

}
