#wrap {
    width:100%;
    // max-width:1204px;
    // max-width:752px;
    min-width:320px;
    margin:0 auto;
    height:100vh;
    overflow:hidden;
    // background:rgb(161, 161, 255);
}


.h3-title-wrap {

    @include layout(750);
    max-width:100%;
    position:relative;
    margin-bottom:20rem;
    padding-right:40rem;
    
    h3.title {
        line-height:1.2;
        font-size:20rem;
        font-weight:700;
        margin-bottom:0;
        span {font-size:20rem; font-weight:700;}
    }

    .text {
        display:inline-block;
        margin-top:10rem;
        font-size:14rem;
        color:#555;
        span { font-size:14rem }
    }

    .pagination {

        display:flex;
        gap:8rem;
        position:absolute;
        top:4rem;
        right:0;

        .item {
            width:20rem;
            height:20rem;
            background:$purple40;
            color:#fff;
            font-size:11rem;
            font-weight:700;
            display:flex;
            align-items:center;
            justify-content: center;
            border-radius:50%;
            line-height:1;
            &.active { background:$purple; }
        }

    }

    .more-btn {

        position:absolute;
        top:50%;
        right:0;
        transform: translateY(-50%);

        font-size:14rem;
        color:$black80;
        display:flex;
        gap:4rem;
        align-items: center;

        &::after {
            content:"";
            display:inline-block;
            width:12rem;
            height:12rem;
            background:url('../../../../images/common/icons/icon_more_arr.svg') no-repeat center;
            background-size:contain;
        }

        &.big {
            &::after {
                content:"";
                display:inline-block;
                width:20rem;
                height:20rem;
                background:url('../../../../images/common/icons/icon_big_more.svg') no-repeat center;
                background-size:contain;
            }
        }

    }

}

article {

    height:100vh;
    padding:48rem 0 100rem;
    overflow: auto;
    box-sizing: border-box;

    &>section {
        max-width:750px;
        margin:0 auto;
    }
    
    // 스크롤바 숨기기
    @include hideScrollBar();

    &#homePage,
    &#hotPage,
    &#newPage,
    &#brandPage,
    &#overseasPage,
    &#seasonOffPage,
    &#exhibitionsPage,
    &#productPage {
        padding-top:94rem;
    }

    &#homePage {
        padding-bottom:50rem;
    }

    & > section {
        
        & > .inner {

            @include layout(750);
            max-width:750px;

            & > .title-wrap {

                position:relative;
                margin-top:20rem;
                margin-bottom:30rem;
                
                .text {
                    display:inline-block;
                    margin-top:10rem;
                    font-size:14rem;
                    color:#555;
                    span { font-size:14rem }
                }

                .pagination {

                    display:flex;
                    gap:8rem;
                    position:absolute;
                    top:4rem;
                    right:0;

                    .item {
                        width:20rem;
                        height:20rem;
                        background:$purple40;
                        color:#fff;
                        font-size:11rem;
                        font-weight:700;
                        display:flex;
                        align-items:center;
                        justify-content: center;
                        border-radius:50%;
                        line-height:1;
                        &.active { background:$purple; }
                    }

                }

            }

        }

    }

    .h4-title-wrap {
        
        display:flex;
        align-items: center;
        justify-content: space-between;

        &.border {
            padding-bottom:16rem;
            border-bottom:solid 1px $black40;
        }
    }

    .h5-title-wrap {
        h5 {font-size:14rem; font-weight:700; }
    }

    .gray-box {
        border-radius:8rem;
        background:#F6F6F6;
        padding:22rem 22rem 27rem;
        display:flex;
    }

    .debug-box {
        background:#F6F6F6;
        padding:16rem;
        margin-bottom:20rem;
        &::before {
            display: inline-block;
            content:"개발 확인용 디버그 박스 입니다.";
            font-size:18rem;
            color:rgb(255, 0, 212);
            margin-bottom:10rem;
            font-weight:700;
        }
    }

    .profile-img-wrap {

        display:flex;
        align-items: center;
        justify-content: center;

        label {
            cursor: pointer;
        }

        .img-wrap {

            position:relative;
            flex-shrink: 0;

            img {
                width:80rem;
                height:80rem;
                border-radius:50%;
            }

            &::after {
                content:"";
                display:inline-block;
                width:30rem;
                height:30rem;
                background:url('../../../../images/common/icons/icon_camera.svg') no-repeat center;
                background-size:contain;
                position:absolute;
                bottom:0;
                right:-10rem;
                z-index: 1;
            }
        }

        .title { 
            font-size:16rem; 
            font-weight:600; 
            text-align: center;
            margin-top:10rem;
        }
    }

}

.white-wrap {

    background:#fff;
    padding:20rem 16rem;
    border-bottom:solid 8rem $black20;
    max-width:750px;
    margin:0 auto;

    &:last-child {
        border-bottom:0;
        padding-bottom:0;
    }

    .title {
        display:inline-block;
        font-size:16rem;
        color:$black80;
        margin-bottom:6rem;
    }

    .order-infos {
        display:flex;
        flex-direction: column;
        gap:10rem;

        li {
            display:flex;
            justify-content: space-between;
            span { font-size:14rem; color:$black80; }
        }
    }
    
}

.toggle-wrap {

    overflow:hidden;
    height:auto;
    transition: 0.5s;

    &.active {

        height:69rem;

        & ul,.input-box,p,span {
            display:none !important;
        }

        .toggle {
            background:url('../../../../images/common/icons/icon_up_arr.svg') no-repeat top right;
            background-size:contain;
        }

    }

}

.white-dim-hidden { 
    overflow: hidden;
    position: relative;
    &::after {
        content:"";
        width:100%;
        height:100rem;
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        position:absolute;
        bottom:0;
        left:50%;
        transform:translateX(-50%);
    }
}


#footer {
    background:$black10;
    padding:20rem 10rem 30rem;

    .inner {
        display: flex;
        flex-direction: column;
        gap:3rem;
        padding:0;
    }

    p {
        font-size:12rem;
        color:$black80;
    }
}