.icon-search {
    background:url('../../../images/common/icons/icon_search.svg') no-repeat center;
    @include iconSize(20);
}
.icon-b2b {
    background:url('../../../images/common/icons/icon_b2b.svg') no-repeat center;
    @include iconSize(20);
}
.icon-language {
    background:url('../../../images/common/icons/icon_language.svg') no-repeat center;
    @include iconSize(20);
}
.icon-notification {
    background:url('../../../images/common/icons/icon_notification.svg') no-repeat center;
    @include iconSize(20);
}
.icon-shoppingbag {
    background:url('../../../images/common/icons/icon_shoppingbag.svg') no-repeat center;
    @include iconSize(20);
}
.icon-setting {
    background:url('../../../images/common/icons/icon_setting.svg') no-repeat center;
    @include iconSize(24);
}
.icon-coupon {
    background:url('../../../images/common/icons/icon_coupon.svg') no-repeat center;
    @include iconSize(16);
    &.color { background:url('../../../images/common/icons/icon_coupon_color.svg') no-repeat center; background-size:contain; }
    &.white { background:url('../../../images/common/icons/icon_coupon_white.svg') no-repeat center; @include iconSize(20); }
}
.icon-star-fill {
    background:url('../../../images/common/icons/icon_star_fill.svg') no-repeat center !important; background-size:contain !important;
    @include iconSize(14);
}
.icon-star-empty {
    background:url('../../../images/common/icons/icon_star_empty.svg') no-repeat center;
    @include iconSize(14);
    &.gray {background:url('../../../images/common/icons/icon_star_gray_empty.svg') no-repeat center; background-size:contain !important;}
    &.big {background:url('../../../images/common/icons/icon_star_big_empty.svg') no-repeat center !important; background-size:contain !important; }
    &.active {background:url('../../../images/common/icons/icon_star_fill.svg') no-repeat center !important; background-size:contain !important;}
}
.icon-star-half {
    background: url('../../../images/common/icons/icon_star_half.svg') no-repeat center;
    background-size: contain !important;
    display: inline-block;
    width: 14px;
    height: 14px;
}
.icon-more-dots {
    background:url('../../../images/common/icons/icon_more_dots.svg') no-repeat center;
    @include iconSize(20);
}
.icon-more {
    background:url('../../../images/common/icons/icon_purple_arr.svg') no-repeat center;
    @include iconSize(12);
}
.icon-white-heart {
    background:url('../../../images/common/icons/icon_heart_white.svg') no-repeat center;
    &.active {
        background:url('../../../images/common/icons/icon_heart_purple.svg') no-repeat center;
    }
    @include iconSize(20);
}
.icon-heart {
    background:url('../../../images/common/icons/icon_heart_gray.svg') no-repeat center;
    background-size:contain;
    &.white {
        background:url('../../../images/common/icons/icon_heart_white.svg') no-repeat center;
        background-size:contain;
    }
    &.active {
        background:url('../../../images/common/icons/icon_heart_purple.svg') no-repeat center;
        background-size:contain;
    }
    @include iconSize(24);
}
.icon-private {
    background:url('../../../images/common/icons/icon_private.svg') no-repeat center; background-size:contain;
    &.visibled {
        background:url('../../../images/common/icons/icon_private_visibled.svg') no-repeat center; background-size:contain;
    }
    @include iconSize(24);

}
.icon-logo-yellow {
    // background:url('../../../images/common/logo_yellow.svg') no-repeat center;
    display:inline-block;
    width:210rem;
    height:40rem;
    background-size:contain;
}
.icon-logo-white {
    // background:url('../../../images/common/logo_white.svg') no-repeat center;
    display:inline-block;
    width:157rem;
    height:30rem;
    margin-left: 5rem;
}
.icon-duck {
    background:url('../../../images/login/duck.svg') no-repeat center;
    @include iconSize(110);
}
.icon-change {
    background:url('../../../images/common/icons/icon_change.svg') no-repeat center;
    @include iconSize(20);
}
.icon-share {
    background:url('../../../images/common/icons/icon_share.svg') no-repeat center;
    @include iconSize(20);
}
.icon-x {
    background:url('../../../images/common/icons/icon_x.svg') no-repeat center;
    @include iconSize(19);
}
.icon-kakao {
    background:url('../../../images/common/icons/icon_kakao.svg') no-repeat center;
    @include iconSize(50);
}
.icon-naver {
    background:url('../../../images/common/icons/icon_naver.svg') no-repeat center;
    @include iconSize(50);
}
.icon-google {
    background:url('../../../images/common/icons/icon_google.svg') no-repeat center;
    @include iconSize(50);
}
// .icon-heart {
//     background:url('../../../images/common/icons/icon_heart.svg') no-repeat center;
//     @include iconSize(14);
// }
.icon-hearts {
    background:url('../../../images/common/icons/icon_hearts.svg') no-repeat center;
    @include iconSize(14);
}
.icon-history {
    background:url('../../../images/common/icons/icon_history.svg') no-repeat center;
    @include iconSize(14);
}
.icon-list {
    background:url('../../../images/common/icons/icon_list.svg') no-repeat center;
    @include iconSize(14);
}
.icon-option {
    background:url('../../../images/common/icons/icon_view_middle.svg') no-repeat center;
    @include iconSize(17);
}
.icon-review {
    background:url('../../../images/common/icons/icon_review.svg') no-repeat center;
    @include iconSize(14);
}
.icon-calendar {
    background:url('../../../images/common/icons/icon_calendar.svg') no-repeat center;
    @include iconSize(20);
}
.icon-camera {
    background:url('../../../images/common/icons/icon_camera.svg') no-repeat center;
    @include iconSize(32);
    &.black {
        background:url('../../../images/common/icons/icon_camera_black.svg') no-repeat center;
        @include iconSize(20);  
    }
}
.icon-plus-yellow {
    background:url('../../../images/common/icons/icon_plus_yellow.svg') no-repeat center;
    @include iconSize(20);
}
.icon-download {
    background:url('../../../images/common/icons/icon_download.svg') no-repeat center;
    @include iconSize(20);

    &.gray {
        background:url('../../../images/common/icons/icon_download_gray.svg') no-repeat center;
        @include iconSize(20);
        background-size:contain;
    }

}