#BizPage {
    background-color: $yellow100;
    text-align: center;

    .biz-title {
        background: none;
        color: $black;
        padding: 0;
    }

    .button-section {
        button{
            width: 143rem;
            height: 99rem;
            font-weight: bold;
            border-radius: 8rem;
        }
    }

    .logo {
        width: 105rem;
        height: 20rem;
        margin: 0 auto;
    }
}

