// Common
@import "../../common/reset";
@import "../../common/mixin";
@import "../../common/base";


#frontGuidePage {
  section {
    
    width:100%;
    max-width:750rem;
    margin:0 auto;
    min-height:400rem;
    margin-bottom:40rem;
    border:solid 1px #000;
    padding:20rem;
    box-sizing: border-box;

    &:last-child {
      margin-bottom:0;
    }
  }

  .front-guide-title {
    font-size:32rem;
    background:#000;
    color:#fff;
    padding:4rem;
    text-align: center;
    margin-bottom:20rem;
  }

  .front-list {

    .depth1 {
      display:block;
      padding:2rem 4rem;
      background:$purple;
      color:#fff;
      margin-top:20rem;
    }

    .depth2 {
      display:block;
      margin:4rem 0;
      text-decoration: underline;
      font-weight:500;
      padding-left:10rem;

      &:hover {
        color:$purple;
      }
    }
  }

  .big-tabs {
    display: grid;
    grid-template-columns: repeat(5, 1fr); // 가로 5개
    gap: 10rem; // 간격 설정
    // margin-top: 20rem; // 상단 여백
  }
  
  .big-tab {
    background-color: #f4f4f4; // 배경색
    padding: 20rem; // 내부 여백
    text-align: center; // 중앙 정렬
    border: 1px solid #ddd; // 테두리
    border-radius: 4rem; // 모서리 둥글게
    font-size: 16rem; // 글자 크기
    font-weight: bold; // 글자 두껍게
  }


  
  @media (max-width: 750rem) {
    .big-tabs {
      grid-template-columns: repeat(4, 1fr); // 화면이 작아지면 가로 4개로 변경
    }
  }
  
  @media (max-width: 900rem) {
    .big-tabs {
      grid-template-columns: repeat(3, 1fr); // 화면이 더 작아지면 가로 3개로 변경
    }
  }
  
  @media (max-width: 600rem) {
    .big-tabs {
      grid-template-columns: repeat(2, 1fr); // 화면이 매우 작아지면 가로 2개로 변경
    }
  }
  
  @media (max-width: 400rem) {
    .big-tabs {
      grid-template-columns: 1fr; // 화면이 아주 작아지면 가로 1개로 변경
    }
  } 

}

.biz_contents {
  font-size: 13rem !important;
}

.biz_title {
  font-size: 20rem !important;
  margin-bottom: 4rem;
}