// .tab-menu {
//     display: flex;

// }

// .tab-button {
//     width: 50%;
// }

.slide-tabs {
    width:101%;
    max-width:750px;
    height:46rem;
    position:fixed;
    top:48rem;
    left:50%;
    transform:translateX(-50%);
    z-index:100;
    padding:16rem;
    background:#fff;
    overflow-x:scroll;
    overflow-y:hidden;

    // 스크롤바 숨기기
    @include hideScrollBar();

    .tabs {
        display:flex;
        gap:24rem;
        
        @include respond(pc) {
            justify-content: center;
        }

        .tab {
            display:inline-block;
            font-size:16rem;
            font-weight:500;
            color:#91979E;
            position:relative;
            white-space: nowrap;

            &.active {
                color:$yellow;
                font-weight:700;
                &::before {
                    content:'';
                    display:inline-block;
                    width:100%;
                    height:4rem;
                    background:$yellow;
                    position:absolute;
                    bottom:-12rem;
                    left:0;
                }

            }
            &:last-child {
                padding-right:16rem;

                &::before {
                    width:calc(100% - 16rem);
                }
            }
            // &.home {
            //     width:18rem;
            //     background:url('../../../images/common/icons/icon_tab_home.png') no-repeat center;
            //     background-size:contain;
            //     &.active {
            //         filter:$filter-purple;
            //     }
            // }

        }
    }

    &.purple {
        // border-bottom:solid 1px $black40;
        .tab {
            &.active {
                color:$purple;
                &::before {
                    background:$purple;
                    width:100%;
                }
            }
        }
    }

    &.center {
        .tabs {
            justify-content: center;
            .tab {
                width:100%;
                max-width:82rem;
            }
        }
    }
}