#orderPage {
    
    .order-info-wrap {
        max-width:750px;
        margin:0 auto;
        padding:16rem 16rem 20rem;
        border-bottom:solid 8rem $black20;
        .order-info-list {
            display:flex;
            gap:11rem;
            &>li {
                width:33.33%;
                display:flex;
                padding:16rem 4rem;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap:6rem;
                border-radius:8rem;
                background:$black10;
                strong { font-size:20rem; }
                p { font-size:13rem; color:$black80; font-weight:700;}
            }
        }
        .order-tabs {
            height:42rem;
            padding:6rem;
            background:$black20;
            border-radius:20rem;
            margin:16rem 0 0;
            display:flex;
            gap:6rem;
            justify-content: space-between;
            .tab {
                width:25%;
                font-size:14rem;
                font-weight:700;
                &.active {
                    background:#fff;
                    border-radius:30rem;
                }
            }
        }
    }
    
    .order-list-wrap {
        
        .order-list {

            padding-top:0;

            li {
                padding: 20rem 16rem;
                border-bottom:solid 8rem $black20;

                &:last-child { border-bottom:none; padding-bottom:0; }

                .order-number-wrap {
                    display:flex;
                    justify-content: space-between;
                    margin-bottom:10rem;

                    .order-number,.more-btn {
                        font-size:14rem;
                        color:$black80;
                    }
                    
                    .more-btn {
                        display:flex;
                        gap:4rem;
                        align-items:center;
                        &::after {
                            content:"";
                            display:inline-block;
                            width:12rem;
                            height:12rem;
                            background:url('../../../../images/common/icons/icon_more_arr.svg');
                            background-size:contain;
                        }
                    }
                }

                .status-wrap {
                    display:flex;
                    justify-content: space-between;
                    margin-bottom:10rem;
                    .status {
                        color:$black80;
                    }
                    .caption {
                        font-size:12rem;
                        color:$black80;
                        display:inline-block;
                    }
                }

                .item-info-wrap {
                    
                    display:flex;
                    gap:10rem;
                    margin-top:10rem;

                    .img-wrap {
                        width:100rem;
                        max-height:100rem;
                        // border-radius:8rem;
                        overflow:hidden;
                    }
                    .text-wrap {
                        margin-top:2rem;

                        .date,.brand,.name { font-size: 12rem; color:$black80; }
                        .brand { font-weight:700; margin:6rem 0; }
                        .price { font-size: 14rem; margin-top:6rem; color:$black80; font-weight:700; }

                    }


                }
            }
        }
    }

    .calendar-wrap {
        display:flex;
        gap:6rem;
        align-items: center;
        margin-bottom:16rem;
    }
    
    .order-card {

        padding: 20rem 16rem;
        border-bottom:solid 8rem $black20;

        .order-info-wrap {
            display:flex;
            justify-content: space-between;
            margin-bottom:10rem;

            .order-number,.more-btn {
                font-size:14rem;
                color:$black80;
            }
            
            .more-btn {
                display:flex;
                gap:4rem;
                align-items:center;
                &::after {
                    content:"";
                    display:inline-block;
                    width:12rem;
                    height:12rem;
                    background:url('../../../../images/common/icons/icon_more_arr.svg');
                    background-size:contain;
                }
            }
        }

        .title-wrap {
            display:flex;
            justify-content: space-between;
            margin-bottom:10rem;
            .title {
                color:$black80;
            }
            .caption {
                font-size:12rem;
                color:$black80;
                display:inline-block;
                width:200rem;
                overflow:hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height:1.5;
            }
        }

        .item-info-wrap {
            
            display:flex;
            gap:10rem;

            & + .item-info-wrap {
                margin-top:10rem;
            }


            .img-wrap {
                width:100rem;
                height:100rem;
                overflow:hidden;
                img { height:100%; }
            }
            .text-wrap {
                margin-top:2rem;

                .date,.brand,.name { font-size: 12rem; color:$black80; }
                .brand { font-weight:700; margin:6rem 0; }
                .price { font-size: 14rem; margin-top:6rem; color:$black80; font-weight:700; }

            }
        }
    }

    .section-mymenu-exchange-detail {
        
        .text-wrap {
            display:flex;
            flex-direction: column;
            gap:2rem;
        }

        strong, b, span {font-size:14rem; color:$black80; }
    }

    .section-mymenu-review-write {
        .stars-wrap {
            margin-top:20rem;
            
            .stars {
                width:100%;
                display:flex;
                justify-content: center;
            }
        }
    }

    .section-mymenu-reward {
        .able-reward {
            padding:20rem 16rem;
            display:flex;
            justify-content: space-between;
            align-items: center;
            b,strong { font-weight:600; }
            strong { font-size:22rem; }
        }
        .gray-box {
            display:flex;
            justify-content: space-between;
            align-items:center;
            padding:14rem 16rem;
            background:$black20;
            border-radius:0 !important;
            b, span { font-weight:700; font-size:14rem; }
        }
        .reward-list {
            padding:0 16rem;
            li {
                padding:16rem 0;
                border-bottom:solid 1px $black40;
            
                .top {
                    margin-bottom:6rem;
                    b,span { font-size:12rem; }
                    b::after {
                        content:"";
                        display:inline-block;
                        width:1px;
                        height:12rem;
                        background:$black50;
                        margin: 0 10rem;
                        position:relative;
                        top:2px;
                    }
                    span { color:$black50; }
                }
                .center {
                    display:flex;
                    margin-bottom:6rem;
                    justify-content: space-between;
                    p,b { font-size:14rem; }
                }
                .bottom {
                    display:flex;
                    justify-content: flex-end;
                    .balance {
                        font-size:13rem;
                        color:$black60;
                    }
                }
            }

        }
    }
} 