.tab { cursor:pointer; }

.home-slide-tabs {
    width:101%;
    height:46rem;
    position:fixed;
    top:48rem;
    left:50%;
    transform:translateX(-50%);
    z-index:100;
    padding:16rem;
    background:#fff;
    overflow-x:scroll;
    overflow-y:hidden;
    border-bottom:solid 1px #F2F2F2;

    // 스크롤바 숨기기
    @include hideScrollBar();

    .tabs {
        display:flex;
        gap:24rem;

        @include respond(pc) {
            justify-content: center;
        }

        .tab {
            display:inline-block;
            font-size:16rem;
            font-weight:500;
            color:#91979E;
            position:relative;
            white-space: nowrap;

            &.active {
                color:$yellow;
                font-weight:700;
                &::before {
                    content:'';
                    display:inline-block;
                    width:100%;
                    height:4rem;
                    background:$yellow;
                    position:absolute;
                    bottom:-12rem;
                    left:0;
                }

            }
            &:last-child {
                padding-right:16rem;

                &::before {
                    width:calc(100% - 16rem);
                }
            }
            // &.home {
            //     width:18rem;
            //     background:url('../../../images/common/icons/icon_tab_home.png') no-repeat center;
            //     background-size:contain;
            //     &.active {
            //         filter:$filter-purple;
            //     }
            // }

        }
    }
}
.slide-tabs {
    width:101%;
    height:46rem;
    position:fixed;
    top:48rem;
    left:50%;
    transform:translateX(-50%);
    z-index:100;
    padding:16rem;
    background:#fff;
    overflow-x:scroll;
    overflow-y:hidden;

    &.no-fixed {
        position:relative;
        top:0;
    }

    &.relative {
        position:relative;
    }

    // 스크롤바 숨기기
    @include hideScrollBar();

    .tabs {
        display:flex;
        gap:24rem;
        
        @include respond(pc) {
            justify-content: center;
        }

        .tab {
            display:inline-block;
            font-size:16rem;
            font-weight:500;
            color:#91979E;
            position:relative;
            white-space: nowrap;

            &.active {
                color:$yellow;
                font-weight:700;
                &::before {
                    content:'';
                    display:inline-block;
                    width:100%;
                    height:4rem;
                    background:$yellow;
                    position:absolute;
                    bottom:-12rem;
                    left:0;
                }

            }
            &:last-child {
                padding-right:16rem;

                &::before {
                    width:calc(100% - 16rem);
                }
            }
            // &.home {
            //     width:18rem;
            //     background:url('../../../images/common/icons/icon_tab_home.png') no-repeat center;
            //     background-size:contain;
            //     &.active {
            //         filter:$filter-purple;
            //     }
            // }

        }
    }

    &.purple {
        // border-bottom:solid 1px $black40;
        .tab {
            &.active {
                color:$purple;
                &::before {
                    background:$purple;
                    width:100%;
                }
            }
        }
    }

    &.center {
        .tabs {
            justify-content: center;
            .tab {
                width:100%;
                max-width:82rem;
            }
        }
    }
}
.common-tab-wrap {
    .tabs {
        display:flex;
        width:100%;
        display: none;
        .tab {
            width:33.3333333333%;
            height:42rem;
            text-align: center;
            background:$black20;;
            border:solid 1px $black50;
            border-right:none;
            font-size:14rem;
            color:$black80;
            &:last-child {
                border-right:solid 1px $black50;
            }
            &.active {
                background:#fff;
                font-weight:700;
                border-bottom:none;
            }
        }        
    }
    .content {
        // padding:15rem 16rem;
        // border:solid 1px $black50;
        border-top:none;
        display:flex;
        flex-direction: column;
        gap:10rem;
        .text-wrap {
            display:flex;
            gap:10rem;
            .title {
                width:80rem;
                font-size:14rem !important;
                font-weight:700;
                flex-shrink:0;
                color:$black80;
            }
            .text {
                display:flex;
                gap:2rem;
                flex-direction: column;
                span {
                    font-size:14rem !important; 
                    color:$black80;
                    line-height:22rem;
                }
            }
        }
    }
}
.category-tabs-wrap {
    
    max-width:750px;
    margin:0 auto;

    .tabs {
        display:flex;
        gap:12rem;
        flex-wrap:wrap;

        border-bottom:solid 1px $black30;
        padding:20rem 16rem;
        
        .tab {
            min-width:52rem;
            padding:5rem 16rem;
            text-align: center;
            border-radius:20rem;
            border:solid 1px $black50;
            font-size:14rem;
            color:$black60;
            &.active {
                border:solid 1px $purple;
                background:$purple10;
                color:$purple !important;
                font-weight:700;
                a {
                    color:$purple !important;
                    font-weight:700;
                    font-size:14rem;
                }
            }
        }        
    }
    &.big {
        .tabs {
            
            .tab {
                display:flex;
                align-items: center;
                justify-content: center;
                padding:4rem;
                width:80rem;
                height:68rem;
                border-radius:8rem;
                .badge.gray {
                    display: inline-block;
                    margin-top:2rem;
                    padding:4rem 8rem;
                    background:$black50;
                    color:#fff;
                    border-radius:4rem;
                    font-weight:400;
                }
                a {
                    font-size:14rem;
                    word-break: keep-all;
                }
            }
        }

    }
    &.recent-search {
        margin-top:10rem;
        .tabs {
            padding:0;
            border-bottom:none;
            .delete-x {
                line-height: 1;
                cursor: pointer;
            }
        }
    }
}
.inquiry-tab-wrap {

    margin-bottom:16rem;

    .tabs {

        display: flex;

        .tab {
            display:flex;
            align-items: center;
            font-size:14rem;
            color:$black50;

            &::after {
                content:"";
                display:inline-block;
                width:1px;
                height:12rem;
                background:$black80;
                margin:0 10rem;
            }

            &:last-child::after {
                display: none;
            }

            &.active {

                color:$black80;

                &::before {
                    content:"";
                    display:inline-block;
                    width:20rem;
                    height:20rem;
                    background:url('../../../images/common/icons/icon_check_black.svg') no-repeat center;
                    background-size:contain;
                    margin-right:1rem;
                }
            }
        }

    }    
}

.brand-tabs {
    display:flex;
    gap:16rem;
    .img-wrap {
        margin-top:4rem;
        border:solid 1px $black30;
        border-radius:16rem;
        width:50rem;
        height:50rem;
        margin-bottom:4rem;
        img { width:100%; max-width:40rem; height:100%; object-fit: contain;}
    }
    .title { font-size:13rem; }
}
.location-tabs {

    .tabs {
        
        display:flex;
        gap:10rem;
        flex-wrap:wrap;

        .tab { 
            height:34rem;
            display:flex;
            align-items: center;
            justify-content: center;
            padding:6rem 14rem; 
            background:#fff; 
            border:solid 1px $black60; 
            border-radius:20rem;
            font-size:14rem;
            color:$black60;

            &.active {
                background:$purple;
                color:#fff;
                border:solid 1px $purple;
            }

        }
        
    }   
}

.switch-tab {
    display:flex;
    width:calc(100% - 32rem);
    margin:0 auto;
    margin-top:20rem;
    height:42rem;
    border-radius:20rem;
    background:$black20;
    padding:6rem;
    max-width:750px;
    .tab {
        width:100%;
        color:$black80;
        font-size:14rem;

        &.active {
            background:#fff;
            border-radius:30rem;
            font-weight:700;
        }

    }
}

.bottom-border {
    border: 1px solid $black20;
    margin-left: -20rem;
    margin-right: -20rem;
}