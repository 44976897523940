$black : #000;
$purple : #B305FF;
$light-purple : #876CEF;
$red : #EE6B62;
$green : #698E39;
$yellow : #FFDF39;
$blue : #0D6EFF;
$gray : #DEDEDE;

$black10 : #F8F8F8;
$black20 : #F2F2F2;
$black30 : #DEDEDE;
$black40 : #BDBDBD;
$black50 : #999;
$black60 : #777;
$black70 : #666;
$black80 : #444;
$black90 : #333;
$black100 : #111;


$purple10 : #F4E9FF;
$purple40 : #E58DF3;

$yellow20 : #FEF9E6;
$yellow40 : #FAECAC;
$yellow100 : #FFD100;

html { font-size:1px; }
body { font-size:16rem; }

* {font-family:'Pretendard', sans-serif; font-size:16rem; letter-spacing:-0.02em; line-height:1.4; color:$black90 }

.img-wrap { 
  line-height:0;
  .circle-img {
    width: 100%;
    height: 100%;
    border-radius: 50% !important;
    object-fit: cover;
  }
}

img { width:100%; object-fit: cover; }
.under-line { text-decoration: underline; }
.hide { position: absolute !important; width: 1px !important; height: 1px !important; margin: -1px !important; overflow: hidden !important; clip-path: polygon(0 0, 0 0, 0 0) !important; } 
.disabled { pointer-events: none; color:#ddd !important; }

.a-right {display:inline-block; width:100%; text-align: right; }
.a-center {display:inline-block; width:100%; text-align: center; }

h3 {
    font-size:20rem;
    font-weight:700;
    color:$black100;
}

.text-type-dot {
    li {
        position:relative;
        padding-left:10rem;
        text-align: left;
        &::before {
            content:"";
            display:inline-block;
            width:4rem;
            height:4rem;
            background:$black90;
            position:absolute;
            top:11rem;
            left:0;
            transform:translate(-50%,-50%);
            border-radius: 50%;
        }
    }
}


@include respond(pc) {
    html { font-size:1.5px !important; }
}
@include respond(tablet) {
    html { font-size:1.5px !important; }
}
@include respond(340) {
    html { font-size:0.8px; }
}
@include respond(374) {
    html { font-size:0.9px; }
}
@include respond(375) {
    html { font-size:1px; }
}

@for $i from 1 to 5 {
    .ellipse#{$i}    {
        display: -webkit-box !important;
        -webkit-box-orient: vertical !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        -webkit-line-clamp: $i !important; /* 표시하려는 줄 수 */
      }
}
@for $i from 0 to 200 {
    .f#{$i} {font-size:#{$i}rem !important;}
    .gap#{$i} {gap:#{$i}rem !important;}
}
@for $i from 0 to 1000 {
    .pt#{$i*2} {padding-top:#{$i*2}rem !important;}
    .pl#{$i*2} {padding-left:#{$i*2}rem !important;}
    .pr#{$i*2} {padding-right:#{$i*2}rem !important;}
    .pb#{$i*2} {padding-bottom:#{$i*2}rem !important;}
    .mt#{$i*2} {margin-top:#{$i*2}rem !important;}
    .ml#{$i*2} {margin-left:#{$i*2}rem !important;}
    .mr#{$i*2} {margin-right:#{$i*2}rem !important;}
    .mb#{$i*2} {margin-bottom:#{$i*2}rem !important;}
    .w#{$i*2} {width:100%; max-width:#{$i*2}rem !important;}
    .h#{$i*2} {height:#{$i*2}rem !important;}
}

.w100p { width:100% !important; max-width:100% !important }
.h100p { height:100% !important; max-height:100% !important }

.f-purple { color:$purple !important; }
.f-red { color:$red !important; }
.f-green { color:$green !important; }
.f-blue { color:$blue !important; }
.f-black50 { color:$black50 !important; }
.f-black60 { color:$black60 !important; }
.f-black70 { color:$black70 !important; }
.f-black80 { color:$black80 !important; }

.bg-white { background:#fff !important}
.bg-gray { background:$black10 !important}
.bg-white { background:#fff !important}

.border-black40 { 
    display:inline-block; 
    width:100%; 
    height:1px; 
    background:$black40;
    margin:16rem 0 15rem;
}
.flex {
    display:flex;
    &.between {
        flex-direction: row !important;
        justify-content: space-between !important;
    }
    &.center {
        flex-direction: row !important;
        justify-content: center !important;
    }
    &.a-center {
        flex-direction: row !important;
        align-items: center !important;
    }
}

.flex-grow {
    flex-grow: 1;
}
.justify-content-end {
justify-content: flex-end;
}
.flex-column {
    flex-direction: column !important;
}
.flex-wrap {
    flex-wrap:wrap;
}
.inline-block {
    display:inline-block !important;
}
.break-word {
    display:inline-block;
    word-break: break-all;
}
.keep-word {
    display:inline-block;
    word-break:keep-all;
}

.stars {
    display:flex;
    &.big {
        a,span,button {
            width:36rem !important;
            height:36rem !important;
        }
    }
    &.middle {
        a,span,button {
            width:20rem !important;
            height:20rem !important;
        }
    }
    .count-wrap {
        color:$black50;
    }
}

.starred::after {
    content:"*";
    display: inline-block;
    color:$red;
    position:relative;
    top:-2rem;
    left:3rem;
    font-weight:700;
}

// 아래는 임시 코드

h2 {
    color:#fff;
    background:#000;
    font-size:40rem;
    padding:20rem;
    text-align: center;
}

.z-index102 { z-index:102 !important; }


.mySwiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
}

.tl {
    text-align-last: left;
}

.tr {
    text-align-last: right;
}

.tc {
    text-align: center;
}