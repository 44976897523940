#productPage {
    
    .section-product-info {

        .info-wrap {
            @include layout(750);
            max-width: 100%;
            margin-top:16rem;
    
            .brand {
                font-size:12rem;
                font-weight:700;
            }
            .title {
                font-size:18rem;
                font-weight:600;
                margin:5rem 0;
            }
            .price-wrap {
                margin-top:10rem;
                position:relative;
    
                .before-price {
                    span {
                        font-size:12rem;
                        font-weight:600;
                        &.price {
                            margin-left:4rem;
                            color:$black50;
                            text-decoration: line-through;
                        }
                    }
                }
                .now-price {
                    span {
                        font-size:20rem;
                        font-weight:700;
                        &.price {
                            margin-left:3rem;
                            color:$black80;
                        }
                    }
                }
                .coupon {
                    width:103rem;
                    height:37rem;
                    position:absolute;
                    top:-10rem;
                    right:0;
                }
            }
            .review-wrap {
                display:flex;
                gap:6rem;
                align-items: center;
    
                .review {
                    font-size:12rem;
                    font-weight:600;
                    color:$black60;
                }
            }
        }
        
        .size-wrap {
    
            @include layout(750);
            max-width:100%;
            margin-top:20rem;
            
            .title-wrap {
                display:flex;
                justify-content: space-between;
                font-weight:700;
                margin-bottom:10rem;
            }
            .white-box {
                display:flex;
                justify-content: center;
                gap:32rem;
                padding:14rem;
                background:#fff;
                border-radius:4rem;
                box-shadow:0 0 3rem rgba(0,0,0,0.25);
    
                .item {
                    text-align: center;
                    // padding-top:20rem;
                    .key {
                        margin-top:2rem;
                        font-size:12rem;
                        color:$black70;
                        line-height:22rem;
                        margin-bottom:2rem;
                    }
                    .value {
                        font-size:14rem;
                        font-weight:700;
                        color:$black80;
                        letter-spacing: -0.04em;
                    }
                    &::before {
                        content:"";
                        display:inline-block;
                        width:20rem;
                        height:20rem;
                    }
    
                    &:nth-child(1)::before {
                        background:url('../../../../images/common/icons/icon_height.svg') no-repeat center;
                        background-size:contain;
                    }
                    &:nth-child(2)::before {
                        background:url('../../../../images/common/icons/icon_weight.svg') no-repeat center;
                        background-size:contain;
                    }
                    &:nth-child(3)::before {
                        background:url('../../../../images/common/icons/icon_head.svg') no-repeat center;
                        background-size:contain;
                    }
                    &:nth-child(4)::before {
                        background:url('../../../../images/common/icons/icon_shoe.svg') no-repeat center;
                        background-size:contain;
                    }
                }
            }
    
        }
    
        .delivery-wrap {
    
            padding:20rem 16rem;
            display:flex;
            gap:32rem;
            border-top:solid 8rem $black20;
            border-bottom:solid 8rem $black20;
            margin-top:20rem;
    
            strong {
                color:$black80;
                font-size:14rem;
                font-weight:700;
            }
            span,p {
                font-size:14rem;
                color:$black80;
            }
            span:nth-child(1)::after {
                content:"";
                display:inline-block;
                width:1px;
                height:13rem;
                background:$black40;
                margin:0 5rem;
                position:relative;
                top:2px;
            }
    
        }
    
        .detail-wrap {
            @include layout(750);
            max-width: 100%;

            .btn.more {
                margin-top:26rem;
            }
        }
    }

}