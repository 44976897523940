.badge {
    padding:6rem 4rem;
    border-radius:2rem;
    color:$red;
    line-height:1;
    font-size:10rem;
    background:#FFEDED;
    

    &.blue {

        color:$blue;
        background:#E5EFFF;

    }

    &.white {

        color:$black90;
        background:#fff;
        font-weight:400;
        border:solid 1px $black50;
        border-radius:4rem;
        
    }

    &.purple {

        border-radius: 14rem;
        padding:6rem 8rem;
        background: linear-gradient(#D26CFF, #B305FF);
        color:#fff;
    }

    &.gray {
        padding:6rem 8rem;
        background: $black20;
        color:$black90;
    }

    &.coupon {
        border-radius:2rem;
        font-size:12rem;
        padding:3rem 7rem;
        background:$purple;
        color:#fff;
    }


}