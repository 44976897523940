#zaeLookPage {

    .profile-wrap {

        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .img-wrap {
            width:80rem;
            height:80rem;
            border-radius: 50%;
            overflow:hidden;
            img {
                width:100%;
                height:100%;
            }
        }

        .title {
            font-weight:600;
            margin-top:10rem;
            margin-bottom:30rem;
        }
    }

    .gray-box-wrap {
        display: flex;
        gap:8rem;
        margin-bottom:30rem;
        .gray-box {
            width:50%;
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap:6rem;
            font-size:13rem;
            font-weight:700;
            padding:16rem 0;
            b {
                font-size:20rem;
            }
        }
    }

    .zaezae-option-wrap {
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:20rem;
    }


}

#zaeLookDetailPage {

    .zaezae-option-wrap {
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:20rem;
    }


}