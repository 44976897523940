#mymenuPage {

    .my-wrap {

        padding:16rem 16rem 20rem;
        border-bottom:solid 8rem $black20; 

        .profile-wrap {

            display:flex;
            gap:14rem;
            align-items: center;

            .img-wrap {
                width:80rem;
                height:80rem;
                flex-shrink: 0;
                img {
                    border-radius: 50%;
                    height:100%;
                }
            }

            .text-wrap {

                width:100%;

                .title-wrap {
                    width:100%;
                    display:flex;
                    justify-content: space-between;
                    .title {
                        font-size:18rem;
                        font-weight:700;
                    }
                }

                .email {
                    display: inline-block;
                    font-size:13rem;
                    margin:2rem 0 6rem;
                }
                .points-list {
                    display:flex;
                    gap:10rem;
                    justify-content: space-between;
                    li,b { font-size:14rem; color:$black80; }
                    b { margin-left:4rem; }
                }
            }
        }

        .actions-list {

            display:flex;
            margin-top:26rem;
            justify-content: center;

            li > a {
                display:flex;
                width:70rem;
                height:63rem;
                justify-content:space-between;
                align-items: center;
                flex-direction: column;
                font-size:13rem;
                color:$black80;
                font-weight:700;
                span { width:36rem; height:36rem; }
            }


        }
        
    }

    .my-kids-wrap {

        padding:16rem 16rem 20rem;
        
        .h3-title-wrap { margin-bottom:20rem !important; }

        .my-kids-list {

            display:flex;
            flex-direction: column;
            gap:16rem;

            li.kid {

                cursor: pointer;
                padding:16rem;
                background:#f8f8f8;
                border-radius:10rem;
                display:flex;
                gap:16rem;

                &.active { background:$purple10; }

                .img-wrap {
                    width:60rem;
                    height:60rem;
                    position:relative;
                    flex-shrink: 0;

                    .badge {
                        position:absolute;
                        bottom:-11rem;
                        left:50%;
                        transform:translateX(-50%);
                    }
                }

                .text-wrap {

                    position:relative;
                    width:100%;

                    .title-wrap {
                        display:flex;
                        justify-content: space-between;
                        .title { font-size:14rem; font-weight:700; }
                        .span-wrap {
                            display:flex;
                            gap:10rem;
                            span { font-size: 12rem; }
                        }
                    }

                    .body-list {
                        display:grid;
                        grid-template-columns: 1fr 1fr;
                        gap:6rem 10rem;
                        max-width:190rem;
                        margin-top:6rem;
                        li {
                            display:flex;
                            justify-content: space-between;
                            span { font-size:13rem; color:$black60; }
                            b { font-size:13rem; color:$black80; }
                        }
                    }
                }

            }

            &.type-star {
                .title-wrap {
                    flex-direction: column;
                    .title {
                        display:flex;
                        align-items: center;
                        margin-bottom:6rem;
                        gap:6rem;
                        font-size:18rem !important;
                        span {
                            display:inline-block;
                            font-size:12rem;
                            font-weight:400;
                        }
                    }
                }
                .actions-wrap {
                    position:absolute;
                    top:0;
                    right:0;
                    display:flex;
                    gap:6rem;
                    
                    .more-dots-wrap {
                        display: initial;
                        position:relative;
                        .more-dots-list {

                            min-width:112rem;
                            position:absolute;
                            top:30rem;
                            right:0;
                            padding:16rem;
                            background:#fff;
                            border:solid 1px $black40;
                            border-radius:8rem;
                            display:flex;
                            gap:21rem;
                            flex-direction: column;
                            z-index:10;

                            li {
                                position:relative;

                                &:first-child::after {
                                    content:"";
                                    display:inline-block;
                                    width:100%;
                                    height:1px;
                                    background:#d9d9d9;
                                    position:absolute;
                                    bottom:-10rem;
                                    left:50%;
                                    transform: translateX(-50%);
                                }

                                a {
                                    display:inline-block;
                                    width:100%;
                                    white-space: nowrap;
                                }
                            }

                        }
                    }

                    button {
                        position:relative;
                        width:20rem;
                        height:20rem;
                        &.icon-star-empty.active {
                            &::before {
                                content:"Main Profile";
                                display:inline-block;
                                position:absolute;
                                bottom:-28rem;
                                left:50%;
                                transform:translateX(-50%);
                                padding:6rem 8rem;
                                background:$purple;        
                                font-size:10rem;
                                white-space:nowrap;
                                color:#fff;
                                font-weight:600;
                                border-radius:30rem;
                            }
                            &::after {
                                content:"";
                                display:inline-block;
                                position:absolute;
                                bottom:-6rem;
                                left:50%;
                                transform:translateX(-50%);
                                width: 0;
                                height: 0;
                                border-left: 3rem solid transparent; /* 밑변의 절반 */
                                border-right: 3rem solid transparent; /* 밑변의 절반 */
                                border-bottom: 4rem solid $purple; /* 높이 */
                            }
                        }
                    }

                    
                }
            }

        }

    }

    .section-mymenu-profile-profile {
        .gray-box {
            margin:0 0 20rem;
            padding:10rem;
            border-radius: 0;
            background:$black10;
            b { font-weight:600; }
        }
    }

    .order-info-wrap {
        padding:16rem 16rem 20rem;
        border-bottom:solid 8rem $black20;
        .order-info-list {
            display:flex;
            gap:11rem;
            &>li {
                width:25%;
                display:flex;
                padding:16rem 4rem;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap:6rem;
                border-radius:8rem;
                background:$black10;
                strong { font-size:20rem; }
                p { font-size:13rem; color:$black80; font-weight:700;}
            }
        }
        .order-tabs {
            height:42rem;
            padding:6rem;
            background:$black20;
            border-radius:20rem;
            margin:16rem 0 0;
            display:flex;
            gap:6rem;
            justify-content: space-between;
            .tab {
                width:25%;
                font-size:14rem;
                font-weight:700;
                &.active {
                    background:#fff;
                    border-radius:30rem;
                }
            }
        }
    }
    
    .order-list-wrap {
        
        .order-list {

            padding-top:0;

            li {
                padding: 20rem 16rem;
                border-bottom:solid 8rem $black20;

                &:last-child { border-bottom:none; padding-bottom:0; }

                .order-number-wrap {
                    display:flex;
                    justify-content: space-between;
                    margin-bottom:10rem;

                    .order-number,.more-btn {
                        font-size:14rem;
                        color:$black80;
                    }
                    
                    .more-btn {
                        display:flex;
                        gap:4rem;
                        align-items:center;
                        &::after {
                            content:"";
                            display:inline-block;
                            width:12rem;
                            height:12rem;
                            background:url('../../../../images/common/icons/icon_more_arr.svg');
                            background-size:contain;
                        }
                    }
                }

                .status-wrap {
                    display:flex;
                    justify-content: space-between;
                    margin-bottom:10rem;
                    .status {
                        color:$black80;
                    }
                    .caption {
                        font-size:12rem;
                        color:$black80;
                    }
                }

                .item-info-wrap {
                    
                    display:flex;
                    gap:10rem;

                    .img-wrap {
                        width:100rem;
                        max-height:100rem;
                        // border-radius:8rem;
                        overflow:hidden;
                    }
                    .text-wrap {
                        margin-top:2rem;

                        .date,.brand,.name { font-size: 12rem; color:$black80; }
                        .brand { font-weight:700; margin:6rem 0; }
                        .price { font-size: 14rem; margin-top:6rem; color:$black80; font-weight:700; }

                    }


                }

            }
        }
    }

    .calendar-wrap {
        display:flex;
        gap:6rem;
        align-items: center;
        margin-bottom:16rem;
    }
    
    .order-card {

        padding: 20rem 16rem;
        border-bottom:solid 8rem $black20;

        .order-info-wrap {
            display:flex;
            justify-content: space-between;
            margin-bottom:10rem;

            .order-number,.more-btn {
                font-size:14rem;
                color:$black80;
            }
            
            .more-btn {
                display:flex;
                gap:4rem;
                align-items:center;
                &::after {
                    content:"";
                    display:inline-block;
                    width:12rem;
                    height:12rem;
                    background:url('../../../../images/common/icons/icon_more_arr.svg');
                    background-size:contain;
                }
            }
        }

        .title-wrap {
            display:flex;
            justify-content: space-between;
            margin-bottom:10rem;
            .title {
                color:$black80;
            }
            .caption {
                font-size:12rem;
                color:$black80;
            }
        }

        .item-info-wrap {
            
            display:flex;
            gap:10rem;

            .img-wrap {
                width:100rem;
                max-height:100rem;
                // border-radius:8rem;
                overflow:hidden;
            }
            .text-wrap {
                margin-top:2rem;

                .date,.brand,.name { font-size: 12rem; color:$black80; }
                .brand { font-weight:700; margin:6rem 0; }
                .price { font-size: 14rem; margin-top:6rem; color:$black80; font-weight:700; }

            }
        }

    }

    .section-mymenu-exchange-detail {
        
        .text-wrap {
            display:flex;
            flex-direction: column;
            gap:2rem;
        }

        strong, b, span {font-size:14rem; color:$black80; }
    }

    .section-mymenu-review-write {
        .stars-wrap {
            margin-top:20rem;
            
            .stars {
                width:100%;
                display:flex;
                justify-content: center;
            }
        }
    }

    .section-mymenu-reward {
        .able-reward {
            padding:20rem 16rem;
            display:flex;
            justify-content: space-between;
            align-items: center;
            b,strong { font-weight:600; }
            strong { font-size:22rem; }
        }
        .gray-box {
            display:flex;
            justify-content: space-between;
            align-items:center;
            padding:14rem 16rem;
            background:$black20;
            border-radius:0 !important;
            b, span { font-weight:700; font-size:14rem; }
        }
        .reward-list {
            padding:0 16rem;
            li {
                padding:16rem 0;
                border-bottom:solid 1px $black40;
            
                .top {
                    margin-bottom:6rem;
                    b,span { font-size:12rem; }
                    b::after {
                        content:"";
                        display:inline-block;
                        width:1px;
                        height:12rem;
                        background:$black50;
                        margin: 0 10rem;
                        position:relative;
                        top:2px;
                    }
                    span { color:$black50; }
                }
                .center {
                    display:flex;
                    margin-bottom:6rem;
                    justify-content: space-between;
                    p,b { font-size:14rem; }
                }
                .bottom {
                    display:flex;
                    justify-content: flex-end;
                    .balance {
                        font-size:13rem;
                        color:$black60;
                    }
                }
            }

        }
    }
} 