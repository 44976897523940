//예시코드
// .card {
//     &.small {
//         .img-wrap {
//             img {
//                 // width: 110rem;
//                 // height:140rem;
//                 @include respond(pc) {
//                     // width: 220rem;
//                     // height: 280rem;
//                 }
//             }
//         }
//     }
// }

.card {
    .img-wrap {
        img:not(.circle-img) { border-radius: 0 !important; }
    }

}

.img-wrap {
    &.card2 {
        width: 110rem;
        height: 132rem; 
        padding: 0;
        img {
            max-width: 100%;
            height: 100% !important;;
            object-fit: cover;
        }
    }
}

.tab .info-wrap {
    text-align: left !important;
    font-size: 16rem;
}

#cards2 {
    padding: 0 !important;
}

.card-tabs {
    width:100vw;

    @include respond(pc) {
        width:100%;
    }
    
    margin-left:-20rem !important;
    padding-left:20rem !important;
    .card.small {
        width:100%;
    }
}


