.bottom-bar {
  width: 100%;
  max-width:760px;
  min-height: 50rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;

  &.borer {
    border-top: solid 1px $black20;
  }
}

.bottom-bar-inner {
  @include layout(750);
  max-width:760px;
  position: relative;
  padding: 0 30rem;
  height: 100%;

  .tabs {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 6rem;

    .tab {
      font-size: 10rem;
      color: $black50;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      font-weight: 500;

      &::before {
        content: "";
        display: inline-block;
        width: 24rem;
        height: 24rem;
      }
      &.home::before {
        background: url('../../../../images/common/icons/icon_tab_home.svg') no-repeat center;
        background-size: contain;
      }
      &.category::before {
        background: url('../../../../images/common/icons/icon_tab_category.svg') no-repeat center;
        background-size: contain;
      }
      &.special::before {
        background: url('../../../../images/common/icons/icon_tab_special.svg') no-repeat center;
        background-size: contain;
      }
      &.promotion::before {
        background: url('../../../../images/common/icons/icon_tab_promotion.svg') no-repeat center;
        background-size: contain;
      }
      &.mymenu::before {
        background: url('../../../../images/common/icons/icon_tab_my.svg') no-repeat center;
        background-size: contain;
      }
      &.active.home::before {
        background: url('../../../../images/common/icons/icon_tab_home_active.svg') no-repeat center;
        background-size: contain;
      }
      &.active.category::before {
        background: url('../../../../images/common/icons/icon_tab_category_active.svg') no-repeat center;
        background-size: contain;
      }
      &.active.special::before {
        background: url('../../../../images/common/icons/icon_tab_special_active.svg') no-repeat center;
        background-size: contain;
      }
      &.active.promotion::before {
        background: url('../../../../images/common/icons/icon_tab_promotion_active.svg') no-repeat center;
        background-size: contain;
      }
      &.active.mymenu::before {
        background: url('../../../../images/common/icons/icon_tab_my_active.svg') no-repeat center;
        background-size: contain;
      }
    }
  }

  .btn-wrap {
    position: relative;
    display: flex;
    gap: 10rem;
    
    .btn {
      height: 46rem;
      border-radius: 8rem;
      font-size: 16rem;
      &.gray {
        background: $black40;
        color: #fff;
      }
    }

    .skip-link {
      position: absolute;
      top: -29rem;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      text-align: center;
      font-size: 14rem;
      color: $black60;
      text-decoration: none;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.2) 0%,   
        rgba(255, 255, 255, 1) 50%, 
        rgba(255, 255, 255, 1) 100%    
      );
      padding: 5rem 10rem;
      border-radius: 8rem;
      opacity: 0.5;
      transition: opacity 0.3s ease, background 0.3s ease, color 0.3s ease;

      &:hover {
        opacity: 1;
        text-decoration: underline;
        color: #B305FF;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.3) 0%, 
          rgba(255, 255, 255, 1) 60%,
          rgba(255, 255, 255, 1) 100% 
        );
      }
    }
  }
}

.inner {
  @include layout(1024);
  max-width: 750px;
  padding: 20rem 0;

  .btn-wrap {
    display: flex;
    gap: 10rem;
    
    .btn {
      height: 46rem;
      border-radius: 8rem;
      font-size: 16rem;
      &.gray {
        background: $black40;
        color: #fff;
      }
    }
  }

  .heart-btn {
    display: flex;
    align-items: center;
    font-size: 12rem;
    color: $black60;
    gap: 4rem;
    flex-shrink: 0;
    line-height: 1;
  }
}
