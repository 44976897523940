#loginPage {

    &.main {
        padding-top:0 !important;
        padding-bottom:0 !important;
    }

    .section-login-main01 {

        display:flex;
        flex-direction: column;
        height:100%;
        min-height:680rem;

        .bg-yellow {

            width:100%;
            height:347rem;
            background:$yellow;
            padding:127rem 16rem 30rem;

            .images-wrap {

                display:flex;
                justify-content: space-between;
                align-items: flex-end;
                padding-bottom:23rem;

                .icon-logo-white { margin-bottom:27rem; }
                .icon-duck { margin-right:10rem; }

            }

        }

        .bg-white {

            position:relative;
            width:100%;
            height:100%;
            padding:30rem 16rem;

            .under-line {
                position:absolute;
                bottom:40rem;
                left:50%;
                transform:translateX(-50%);
                font-size:14rem;
                color:$black60;
            }

        }


    }

    .section-login-main02 {
        
        padding-top:180rem;
        padding-bottom:40rem;
        
        .inner {
            
            display:flex;
            flex-direction: column;
            align-items: center;

            .icon-logo-yellow {
                margin-bottom:68rem;
            }

            .input-box {
                width:100%;
            }

            .a-list {

                display:flex;
                margin-top:24rem;
                margin-bottom:70rem;

                li {
                    &::after {
                        content:"";
                        display:inline-block;
                        width:1px;
                        height:14rem;
                        background:$black80;
                        margin:0 10rem;
                    }
                    &:last-child::after {
                        display:none;
                    }
                    a {
                        font-size:14rem;
                        font-weight:500;
                    }
                }
            }

            .sns-wrap {

                .title {
                    text-align: center;
                    font-size:14rem;
                    font-weight:500;
                    margin-bottom:20rem;
                }

                .sns-list {
                    display:flex;
                    gap:20rem;
                    margin-bottom:50rem;
                }

            }

            .under-line {
                color:$black60;
                a {
                    color:$black60;
                    font-size:14rem;
                }
            }

        }
    }

    .section-login-findid {
        .gray-box {
            display:flex;
            align-items: center;
            flex-direction: column;

            .f-purple {
                font-weight:600;
                font-size:20rem;
                line-height:1.4;
                margin-bottom:6rem;
            }

            .under-line {
             font-size:12rem;
            }

        }
    }
}