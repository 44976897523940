.big-tabs {
    display: grid;
    grid-template-columns: repeat(5, 1fr); // 항상 가로 5개
    gap: 8rem; // 간격 설정
    width: 100%; // 컨테이너의 전체 너비
    box-sizing: border-box; // 패딩 및 테두리를 포함한 너비 설정
}

.big-tab {
    aspect-ratio: 59 / 56; // 기본 직사각형 비율
    background-color: $purple10; // 배경색
    color: $purple;
    padding: 4rem; // 내부 여백
    text-align: center; // 중앙 정렬
    border: 1px solid $purple; // 테두리
    border-radius: 8rem; // 모서리 둥글게
    font-size: 12rem; // 글자 크기 (상황에 맞게 조정)
    font-weight: bold; // 글자 두껍게
    display: flex; // Flexbox 사용
    align-items: center; // 세로 가운데 정렬
    justify-content: center; // 가로 가운데 정렬
    cursor: pointer; // 클릭 가능한 커서
    overflow: hidden; /* 텍스트가 넘치지 않도록 설정 */
    text-overflow: ellipsis; /* 넘치는 텍스트는 생략 표시 */
}

@media (max-width: 340px) {
    .big-tab {
        font-size: 10rem; // 매우 작은 화면에서는 글자 크기 조정
    }
}
