.btn {
    display:flex;
    gap:4rem;
    align-items: center;
    justify-content: center;
    padding:6rem;
    font-size:12rem;
    font-weight:700;
    color:#fff;
    border-radius:4rem;
    line-height:1;
    box-sizing: border-box;

    &.purple {
        background:$purple;
    }

    &.white {
        background:#fff;
        color:$purple;
        border:solid 1px $purple;
        &:disabled {
            background:#fff !important;
            border:solid 1px $black50 !important;
            color:$black50 !important;
        }
        &.active {
            background:$purple;
            color:#fff;
        }
    }

    &.white2 {
        background:#fff;
        color:$black80;
        border:solid 1px $black40;
        height:40rem;
    }

    &.light-purple {
        background:$light-purple;
    }

    &.black20 {
        background:$black20;
        color:$black80;
        height:34rem;
        width:100%;
        border-radius:8rem;
        font-size:14rem;
    }
    &.purple10 {
        background:$purple10;
        color:$black80;
        height:34rem;
        width:100%;
        border-radius:8rem;
        font-size:14rem;
    }

    &.big {
        width:100%;
        height:46rem;
        font-size:16rem;
        border-radius:8rem;
    }

    &.round {
        border-radius:30rem !important;
    }

    &.more {
        height:42rem;
        border-radius:21rem;
        background:#fff;
        border:solid 1px $black90;
        color:$black90;
        font-weight:600;

        &::after {
            width:12rem;
            height:12rem;
            background:url('../../../images/common/icons/icon_big_more.svg') no-repeat center;
            transform:rotate(90deg);
            margin-left:8rem;
        }

        &.active::after {
            transform:rotate(-90deg);
            top:0;
        }

    }

    &.shortcuts {
        height:42rem;
        border-radius:21rem;
        background:#fff;
        border:solid 1px $black90;
        color:$black90;
        font-weight:600;

        &::after {
            width:12rem;
            height:12rem;
            background:url('../../../images/common/icons/icon_big_more.svg') no-repeat center;
            // transform:rotate(90deg);
            margin-left:8rem;
        }

        &.active::after {
            // transform:rotate(-90deg);
            top:0;
        }

    }

    &.yellow {
        height:42rem;
        padding:10rem 16rem;
        color:$black90;
        background:$yellow20;
    }

    &.gray {
        width:100%;
        height:34rem;
        background:$black20;
        border-radius:4rem;
        color:$black80;
        font-size:14rem;
    }

    &.pay {
        width:100%;
        height:56rem;
        border-radius: 0;
        background:$purple;
        gap:0;
        span, strong { color:#fff; }
        span { 
            font-size:14rem; 
            font-weight:400;
            &::after {
                content:"";
                display:inline-block;
                width:1px;
                height:12rem;
                margin:0 6rem;
                background:#fff;
                position:relative;
                top:1px;
            }
        }
    }

    &.delivery {
        position:fixed;
        top:8rem;
        right:16rem;
        z-index:102;
        height:32rem;
        font-size:12rem;
        
    }

    &:disabled {
        .icon-download {
            background:url('../../../images/common/icons/icon_download_gray.svg') no-repeat center;
            @include iconSize(20);
            background-size:contain;
        }
    }

}

button {

    &:disabled, &.disabled {
        background:$black40 !important;
        color:#fff !important;
        pointer-events: none;
    }

    &.more {
        
        display:flex;
        align-items: center;
        gap:4rem;
        font-size:12rem;
        font-weight:600;
        color:$black60;
        
        &::after {
            content:"";
            display:inline-block;
            width:12rem;
            height:12rem;
            background:url('../../../images/common/icons/icon_small_more.svg') no-repeat center;
            background-size:contain;
            position:relative;
            top:-1rem;
        }
    }

    &.shortcuts {
        
        display:flex;
        align-items: center;
        gap:4rem;
        font-size:12rem;
        font-weight:600;
        color:$black60;
        
        &::after {
            content:"";
            display:inline-block;
            width:12rem;
            height:12rem;
            background:url('../../../images/common/icons/icon_small_more.svg') no-repeat center;
            background-size:contain;
            position:relative;
            top:-1rem;
        }
    }
}

.btn-wrap {

    &.center {
        display:flex;
        align-items:center;
        justify-content: center;
    }

    &.flex {
        display:flex;
        gap:10rem;
    }

    .title {
        font-weight:500;
        margin-bottom:12rem;
    }

    .btns {

        width:100%;
        display:flex;
        gap:20rem;

        .btn {
            width:100%;
            height:56rem;
            border-radius:8rem;
            color:$black90;
            font-size:16rem;
            &.white { background:#fff; border:none;}
            &.gray { background:$black20; }
        }

    }
}

.fab {

    position:fixed;
    z-index: 110;
    bottom:66rem;
    right:16rem;
    width:40rem;
    height:40rem;

    .action {
        width:40rem;
        height:40rem;
        border-radius:50%;

        &.plus {
            background:$black80 url('../../../images/common/icons/icon_plus_yellow.svg') no-repeat center;
            background-size:14rem 14rem;
            transition:0.5s;
            position:relative;
            z-index:110;
        }

        &.profile, &.zaelook {
            background:$yellow;
            color:$black80;
            font-size:12rem;
            font-weight:700;
            position:absolute;
            bottom:0;
            right:0;
            z-index:109;
            opacity:0;
            transition:0.5s;
        }
    }

    &.active {
        .action.plus {
            transform:rotate(-45deg);
        }

        .action.profile {
            opacity:1;
            bottom:50rem;
        }
        .action.zaelook {
            opacity:1;
            bottom:100rem;
        }

    }

}

.a-more-btn {
    display:flex;
    gap:4rem;
    align-items:center;
    font-size:14rem;
    &::after {
        content:"";
        display:inline-block;
        width:12rem;
        height:12rem;
        background:url('../../../images/common/icons/icon_more_arr.svg');
        background-size:contain;
    }

    &.title {
        display: flex;
        gap:6rem;
    }

    &.f-purple::after {
        background:url('../../../images/common/icons/icon_more_arr_purple.svg') no-repeat;
        background-size:contain;
    }

}

.lock-btn {

    display:flex;
    gap:8rem;
    align-items: center;

    &::before {
        content:"";
        display:inline-block;
        width:16rem;
        height:16rem;
        background:url('../../../images/common/icons/icon_lock.svg') no-repeat center;
        background-size:contain;
    }

    &.unlock {
        &::before {
            content:"";
            display:inline-block;
            width:16rem;
            height:16rem;
            background:url('../../../images/common/icons/icon_unlock.svg') no-repeat center;
            background-size:contain;
        }
    }

}

.toggle {
    display: inline-block;
    min-width:20rem;
    min-height:20rem;
    background:url('../../../images/common/icons/icon_under_arr.svg') no-repeat top 4rem right;
    background-size:12rem 12rem;
    padding-right:35rem;
    font-size:14rem;
    color:$black80;
    line-height:1;

    &.big {
        background:url('../../../images/common/icons/icon_under_arr.svg') no-repeat top 0 right;
        background-size:20rem 20rem;
        line-height:1;
        height:20rem;
    }

    b { font-size:14rem; }
}